@import "../colors";
@import "../variables";
@import "../mixin";

.dln-dt-popup {
    @media (max-width: $breakpoint-mobile) {
        height: 100vh;
        overflow: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        background: rgba(0, 0, 0, 0.1);
    }
}

dln-date-time-inline dln-date-time-container {
    @include box-shadow(none !important);
}

dln-date-time-container.dln-dt-popup-container, dln-dialog-container {
    width: 440px;

    @media (max-width: $breakpoint-mobile) {
        margin: 25% 15px;
        width: calc(100vw - 30px);
    }

    @media (max-width: $breakpoint-mobile) and (orientation: landscape) {
        margin: 15px;
    }
}

