$flag-icon-css-path: '/content/fonts/flags/sprite.svg' !default;

.flag-icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url($flag-icon-css-path);
}
.flag-icon-zw {
    width: 27px;
    height: 20px;
    background-position: 0 0;
}
.flag-icon-zm {
    width: 27px;
    height: 20px;
    background-position: -36px 0;
}
.flag-icon-za {
    width: 27px;
    height: 20px;
    background-position: -72px 0;
}
.flag-icon-yt {
    width: 27px;
    height: 20px;
    background-position: -108px 0;
}
.flag-icon-ye {
    width: 27px;
    height: 20px;
    background-position: -144px 0;
}
.flag-icon-ws {
    width: 27px;
    height: 20px;
    background-position: -180px 0;
}
.flag-icon-wf {
    width: 27px;
    height: 20px;
    background-position: -216px 0;
}
.flag-icon-vu {
    width: 27px;
    height: 20px;
    background-position: -252px 0;
}
.flag-icon-vn {
    width: 27px;
    height: 20px;
    background-position: -288px 0;
}
.flag-icon-vi {
    width: 27px;
    height: 20px;
    background-position: -324px 0;
}
.flag-icon-vg {
    width: 27px;
    height: 20px;
    background-position: -360px 0;
}
.flag-icon-ve {
    width: 27px;
    height: 20px;
    background-position: -396px 0;
}
.flag-icon-vc {
    width: 27px;
    height: 20px;
    background-position: -432px 0;
}
.flag-icon-va {
    width: 27px;
    height: 20px;
    background-position: -468px 0;
}
.flag-icon-uz {
    width: 27px;
    height: 20px;
    background-position: -504px 0;
}
.flag-icon-uy {
    width: 27px;
    height: 20px;
    background-position: 0 -36px;
}
.flag-icon-us {
    width: 27px;
    height: 20px;
    background-position: -36px -36px;
}
.flag-icon-un {
    width: 27px;
    height: 20px;
    background-position: -72px -36px;
}
.flag-icon-um {
    width: 27px;
    height: 20px;
    background-position: -108px -36px;
}
.flag-icon-ug {
    width: 27px;
    height: 20px;
    background-position: -144px -36px;
}
.flag-icon-ua {
    width: 27px;
    height: 20px;
    background-position: -180px -36px;
}
.flag-icon-tz {
    width: 27px;
    height: 20px;
    background-position: -216px -36px;
}
.flag-icon-tw {
    width: 27px;
    height: 20px;
    background-position: -252px -36px;
}
.flag-icon-tv {
    width: 27px;
    height: 20px;
    background-position: -288px -36px;
}
.flag-icon-tt {
    width: 27px;
    height: 20px;
    background-position: -324px -36px;
}
.flag-icon-tr {
    width: 27px;
    height: 20px;
    background-position: -360px -36px;
}
.flag-icon-to {
    width: 27px;
    height: 20px;
    background-position: -396px -36px;
}
.flag-icon-tn {
    width: 27px;
    height: 20px;
    background-position: -432px -36px;
}
.flag-icon-tm {
    width: 27px;
    height: 20px;
    background-position: -468px -36px;
}
.flag-icon-tl {
    width: 27px;
    height: 20px;
    background-position: -504px -36px;
}
.flag-icon-tk {
    width: 27px;
    height: 20px;
    background-position: 0 -72px;
}
.flag-icon-tj {
    width: 27px;
    height: 20px;
    background-position: -36px -72px;
}
.flag-icon-th {
    width: 27px;
    height: 20px;
    background-position: -72px -72px;
}
.flag-icon-tg {
    width: 27px;
    height: 20px;
    background-position: -108px -72px;
}
.flag-icon-tf {
    width: 27px;
    height: 20px;
    background-position: -144px -72px;
}
.flag-icon-td {
    width: 27px;
    height: 20px;
    background-position: -180px -72px;
}
.flag-icon-tc {
    width: 27px;
    height: 20px;
    background-position: -216px -72px;
}
.flag-icon-sz {
    width: 27px;
    height: 20px;
    background-position: -252px -72px;
}
.flag-icon-sy {
    width: 27px;
    height: 20px;
    background-position: -288px -72px;
}
.flag-icon-sx {
    width: 27px;
    height: 20px;
    background-position: -324px -72px;
}
.flag-icon-sv {
    width: 27px;
    height: 20px;
    background-position: -360px -72px;
}
.flag-icon-st {
    width: 27px;
    height: 20px;
    background-position: -396px -72px;
}
.flag-icon-ss {
    width: 27px;
    height: 20px;
    background-position: -432px -72px;
}
.flag-icon-sr {
    width: 27px;
    height: 20px;
    background-position: -468px -72px;
}
.flag-icon-so {
    width: 27px;
    height: 20px;
    background-position: -504px -72px;
}
.flag-icon-sn {
    width: 27px;
    height: 20px;
    background-position: 0 -108px;
}
.flag-icon-sm {
    width: 27px;
    height: 20px;
    background-position: -36px -108px;
}
.flag-icon-sl {
    width: 27px;
    height: 20px;
    background-position: -72px -108px;
}
.flag-icon-sk {
    width: 27px;
    height: 20px;
    background-position: -108px -108px;
}
.flag-icon-sj {
    width: 27px;
    height: 20px;
    background-position: -144px -108px;
}
.flag-icon-si {
    width: 27px;
    height: 20px;
    background-position: -180px -108px;
}
.flag-icon-sh {
    width: 27px;
    height: 20px;
    background-position: -216px -108px;
}
.flag-icon-sg {
    width: 27px;
    height: 20px;
    background-position: -252px -108px;
}
.flag-icon-se {
    width: 27px;
    height: 20px;
    background-position: -288px -108px;
}
.flag-icon-sd {
    width: 27px;
    height: 20px;
    background-position: -324px -108px;
}
.flag-icon-sc {
    width: 27px;
    height: 20px;
    background-position: -360px -108px;
}
.flag-icon-sb {
    width: 27px;
    height: 20px;
    background-position: -396px -108px;
}
.flag-icon-sa {
    width: 27px;
    height: 20px;
    background-position: -432px -108px;
}
.flag-icon-rw {
    width: 27px;
    height: 20px;
    background-position: -468px -108px;
}
.flag-icon-ru {
    width: 27px;
    height: 20px;
    background-position: -504px -108px;
}
.flag-icon-rs {
    width: 27px;
    height: 20px;
    background-position: 0 -144px;
}
.flag-icon-ro {
    width: 27px;
    height: 20px;
    background-position: -36px -144px;
}
.flag-icon-re {
    width: 27px;
    height: 20px;
    background-position: -72px -144px;
}
.flag-icon-qa {
    width: 27px;
    height: 20px;
    background-position: -108px -144px;
}
.flag-icon-py {
    width: 27px;
    height: 20px;
    background-position: -144px -144px;
}
.flag-icon-pw {
    width: 27px;
    height: 20px;
    background-position: -180px -144px;
}
.flag-icon-pt {
    width: 27px;
    height: 20px;
    background-position: -216px -144px;
}
.flag-icon-ps {
    width: 27px;
    height: 20px;
    background-position: -252px -144px;
}
.flag-icon-pr {
    width: 27px;
    height: 20px;
    background-position: -288px -144px;
}
.flag-icon-pn {
    width: 27px;
    height: 20px;
    background-position: -324px -144px;
}
.flag-icon-pm {
    width: 27px;
    height: 20px;
    background-position: -360px -144px;
}
.flag-icon-pl {
    width: 27px;
    height: 20px;
    background-position: -396px -144px;
}
.flag-icon-pk {
    width: 27px;
    height: 20px;
    background-position: -432px -144px;
}
.flag-icon-ph {
    width: 27px;
    height: 20px;
    background-position: -468px -144px;
}
.flag-icon-pg {
    width: 27px;
    height: 20px;
    background-position: -504px -144px;
}
.flag-icon-pf {
    width: 27px;
    height: 20px;
    background-position: 0 -180px;
}
.flag-icon-pe {
    width: 27px;
    height: 20px;
    background-position: -36px -180px;
}
.flag-icon-pa {
    width: 27px;
    height: 20px;
    background-position: -72px -180px;
}
.flag-icon-om {
    width: 27px;
    height: 20px;
    background-position: -108px -180px;
}
.flag-icon-nz {
    width: 27px;
    height: 20px;
    background-position: -144px -180px;
}
.flag-icon-nu {
    width: 27px;
    height: 20px;
    background-position: -180px -180px;
}
.flag-icon-nr {
    width: 27px;
    height: 20px;
    background-position: -216px -180px;
}
.flag-icon-np {
    width: 27px;
    height: 20px;
    background-position: -252px -180px;
}
.flag-icon-no {
    width: 27px;
    height: 20px;
    background-position: -288px -180px;
}
.flag-icon-nl {
    width: 27px;
    height: 20px;
    background-position: -324px -180px;
}
.flag-icon-ni {
    width: 27px;
    height: 20px;
    background-position: -360px -180px;
}
.flag-icon-ng {
    width: 27px;
    height: 20px;
    background-position: -396px -180px;
}
.flag-icon-nf {
    width: 27px;
    height: 20px;
    background-position: -432px -180px;
}
.flag-icon-ne {
    width: 27px;
    height: 20px;
    background-position: -468px -180px;
}
.flag-icon-nc {
    width: 27px;
    height: 20px;
    background-position: -504px -180px;
}
.flag-icon-na {
    width: 27px;
    height: 20px;
    background-position: 0 -216px;
}
.flag-icon-mz {
    width: 27px;
    height: 20px;
    background-position: -36px -216px;
}
.flag-icon-my {
    width: 27px;
    height: 20px;
    background-position: -72px -216px;
}
.flag-icon-mx {
    width: 27px;
    height: 20px;
    background-position: -108px -216px;
}
.flag-icon-mw {
    width: 27px;
    height: 20px;
    background-position: -144px -216px;
}
.flag-icon-mv {
    width: 27px;
    height: 20px;
    background-position: -180px -216px;
}
.flag-icon-mu {
    width: 27px;
    height: 20px;
    background-position: -216px -216px;
}
.flag-icon-mt {
    width: 27px;
    height: 20px;
    background-position: -252px -216px;
}
.flag-icon-ms {
    width: 27px;
    height: 20px;
    background-position: -288px -216px;
}
.flag-icon-mr {
    width: 27px;
    height: 20px;
    background-position: -324px -216px;
}
.flag-icon-mq {
    width: 27px;
    height: 20px;
    background-position: -360px -216px;
}
.flag-icon-mp {
    width: 27px;
    height: 20px;
    background-position: -396px -216px;
}
.flag-icon-mo {
    width: 27px;
    height: 20px;
    background-position: -432px -216px;
}
.flag-icon-mn {
    width: 27px;
    height: 20px;
    background-position: -468px -216px;
}
.flag-icon-mm {
    width: 27px;
    height: 20px;
    background-position: -504px -216px;
}
.flag-icon-ml {
    width: 27px;
    height: 20px;
    background-position: 0 -252px;
}
.flag-icon-mk {
    width: 27px;
    height: 20px;
    background-position: -36px -252px;
}
.flag-icon-mh {
    width: 27px;
    height: 20px;
    background-position: -72px -252px;
}
.flag-icon-mg {
    width: 27px;
    height: 20px;
    background-position: -108px -252px;
}
.flag-icon-mf {
    width: 27px;
    height: 20px;
    background-position: -144px -252px;
}
.flag-icon-me {
    width: 27px;
    height: 20px;
    background-position: -180px -252px;
}
.flag-icon-md {
    width: 27px;
    height: 20px;
    background-position: -216px -252px;
}
.flag-icon-mc {
    width: 27px;
    height: 20px;
    background-position: -252px -252px;
}
.flag-icon-ma {
    width: 27px;
    height: 20px;
    background-position: -288px -252px;
}
.flag-icon-ly {
    width: 27px;
    height: 20px;
    background-position: -324px -252px;
}
.flag-icon-lv {
    width: 27px;
    height: 20px;
    background-position: -360px -252px;
}
.flag-icon-lu {
    width: 27px;
    height: 20px;
    background-position: -396px -252px;
}
.flag-icon-lt {
    width: 27px;
    height: 20px;
    background-position: -432px -252px;
}
.flag-icon-ls {
    width: 27px;
    height: 20px;
    background-position: -468px -252px;
}
.flag-icon-lr {
    width: 27px;
    height: 20px;
    background-position: -504px -252px;
}
.flag-icon-lk {
    width: 27px;
    height: 20px;
    background-position: 0 -288px;
}
.flag-icon-li {
    width: 27px;
    height: 20px;
    background-position: -36px -288px;
}
.flag-icon-lc {
    width: 27px;
    height: 20px;
    background-position: -72px -288px;
}
.flag-icon-lb {
    width: 27px;
    height: 20px;
    background-position: -108px -288px;
}
.flag-icon-la {
    width: 27px;
    height: 20px;
    background-position: -144px -288px;
}
.flag-icon-kz {
    width: 27px;
    height: 20px;
    background-position: -180px -288px;
}
.flag-icon-ky {
    width: 27px;
    height: 20px;
    background-position: -216px -288px;
}
.flag-icon-kw {
    width: 27px;
    height: 20px;
    background-position: -252px -288px;
}
.flag-icon-kr {
    width: 27px;
    height: 20px;
    background-position: -288px -288px;
}
.flag-icon-kp {
    width: 27px;
    height: 20px;
    background-position: -324px -288px;
}
.flag-icon-kn {
    width: 27px;
    height: 20px;
    background-position: -360px -288px;
}
.flag-icon-km {
    width: 27px;
    height: 20px;
    background-position: -396px -288px;
}
.flag-icon-ki {
    width: 27px;
    height: 20px;
    background-position: -432px -288px;
}
.flag-icon-kh {
    width: 27px;
    height: 20px;
    background-position: -468px -288px;
}
.flag-icon-kg {
    width: 27px;
    height: 20px;
    background-position: -504px -288px;
}
.flag-icon-ke {
    width: 27px;
    height: 20px;
    background-position: 0 -324px;
}
.flag-icon-jp {
    width: 27px;
    height: 20px;
    background-position: -36px -324px;
}
.flag-icon-jo {
    width: 27px;
    height: 20px;
    background-position: -72px -324px;
}
.flag-icon-jm {
    width: 27px;
    height: 20px;
    background-position: -108px -324px;
}
.flag-icon-je {
    width: 27px;
    height: 20px;
    background-position: -144px -324px;
}
.flag-icon-it {
    width: 27px;
    height: 20px;
    background-position: -180px -324px;
}
.flag-icon-is {
    width: 27px;
    height: 20px;
    background-position: -216px -324px;
}
.flag-icon-ir {
    width: 27px;
    height: 20px;
    background-position: -252px -324px;
}
.flag-icon-iq {
    width: 27px;
    height: 20px;
    background-position: -288px -324px;
}
.flag-icon-io {
    width: 27px;
    height: 20px;
    background-position: -324px -324px;
}
.flag-icon-in {
    width: 27px;
    height: 20px;
    background-position: -360px -324px;
}
.flag-icon-im {
    width: 27px;
    height: 20px;
    background-position: -396px -324px;
}
.flag-icon-il {
    width: 27px;
    height: 20px;
    background-position: -432px -324px;
}
.flag-icon-ie {
    width: 27px;
    height: 20px;
    background-position: -468px -324px;
}
.flag-icon-id {
    width: 27px;
    height: 20px;
    background-position: -504px -324px;
}
.flag-icon-hu {
    width: 27px;
    height: 20px;
    background-position: 0 -360px;
}
.flag-icon-ht {
    width: 27px;
    height: 20px;
    background-position: -36px -360px;
}
.flag-icon-hr {
    width: 27px;
    height: 20px;
    background-position: -72px -360px;
}
.flag-icon-hn {
    width: 27px;
    height: 20px;
    background-position: -108px -360px;
}
.flag-icon-hm {
    width: 27px;
    height: 20px;
    background-position: -144px -360px;
}
.flag-icon-hk {
    width: 27px;
    height: 20px;
    background-position: -180px -360px;
}
.flag-icon-gy {
    width: 27px;
    height: 20px;
    background-position: -216px -360px;
}
.flag-icon-gw {
    width: 27px;
    height: 20px;
    background-position: -252px -360px;
}
.flag-icon-gu {
    width: 27px;
    height: 20px;
    background-position: -288px -360px;
}
.flag-icon-gt {
    width: 27px;
    height: 20px;
    background-position: -324px -360px;
}
.flag-icon-gs {
    width: 27px;
    height: 20px;
    background-position: -360px -360px;
}
.flag-icon-gr {
    width: 27px;
    height: 20px;
    background-position: -396px -360px;
}
.flag-icon-gq {
    width: 27px;
    height: 20px;
    background-position: -432px -360px;
}
.flag-icon-gp {
    width: 27px;
    height: 20px;
    background-position: -468px -360px;
}
.flag-icon-gn {
    width: 27px;
    height: 20px;
    background-position: -504px -360px;
}
.flag-icon-gm {
    width: 27px;
    height: 20px;
    background-position: 0 -396px;
}
.flag-icon-gl {
    width: 27px;
    height: 20px;
    background-position: -36px -396px;
}
.flag-icon-gi {
    width: 27px;
    height: 20px;
    background-position: -72px -396px;
}
.flag-icon-gh {
    width: 27px;
    height: 20px;
    background-position: -108px -396px;
}
.flag-icon-gg {
    width: 27px;
    height: 20px;
    background-position: -144px -396px;
}
.flag-icon-gf {
    width: 27px;
    height: 20px;
    background-position: -180px -396px;
}
.flag-icon-ge {
    width: 27px;
    height: 20px;
    background-position: -216px -396px;
}
.flag-icon-gd {
    width: 27px;
    height: 20px;
    background-position: -252px -396px;
}
.flag-icon-gb {
    width: 27px;
    height: 20px;
    background-position: -288px -396px;
}
.flag-icon-gb-wls {
    width: 27px;
    height: 20px;
    background-position: -324px -396px;
}
.flag-icon-gb-sct {
    width: 27px;
    height: 20px;
    background-position: -360px -396px;
}
.flag-icon-gb-nir {
    width: 27px;
    height: 20px;
    background-position: -396px -396px;
}
.flag-icon-gb-eng {
    width: 27px;
    height: 20px;
    background-position: -432px -396px;
}
.flag-icon-ga {
    width: 27px;
    height: 20px;
    background-position: -468px -396px;
}
.flag-icon-fr {
    width: 27px;
    height: 20px;
    background-position: -504px -396px;
}
.flag-icon-fo {
    width: 27px;
    height: 20px;
    background-position: 0 -432px;
}
.flag-icon-fm {
    width: 27px;
    height: 20px;
    background-position: -36px -432px;
}
.flag-icon-fk {
    width: 27px;
    height: 20px;
    background-position: -72px -432px;
}
.flag-icon-fj {
    width: 27px;
    height: 20px;
    background-position: -108px -432px;
}
.flag-icon-fi {
    width: 27px;
    height: 20px;
    background-position: -144px -432px;
}
.flag-icon-eu {
    width: 27px;
    height: 20px;
    background-position: -180px -432px;
}
.flag-icon-et {
    width: 27px;
    height: 20px;
    background-position: -216px -432px;
}
.flag-icon-es {
    width: 27px;
    height: 20px;
    background-position: -252px -432px;
}
.flag-icon-er {
    width: 27px;
    height: 20px;
    background-position: -288px -432px;
}
.flag-icon-eh {
    width: 27px;
    height: 20px;
    background-position: -324px -432px;
}
.flag-icon-eg {
    width: 27px;
    height: 20px;
    background-position: -360px -432px;
}
.flag-icon-ee {
    width: 27px;
    height: 20px;
    background-position: -396px -432px;
}
.flag-icon-ec {
    width: 27px;
    height: 20px;
    background-position: -432px -432px;
}
.flag-icon-dz {
    width: 27px;
    height: 20px;
    background-position: -468px -432px;
}
.flag-icon-do {
    width: 27px;
    height: 20px;
    background-position: -504px -432px;
}
.flag-icon-dm {
    width: 27px;
    height: 20px;
    background-position: 0 -468px;
}
.flag-icon-dk {
    width: 27px;
    height: 20px;
    background-position: -36px -468px;
}
.flag-icon-dj {
    width: 27px;
    height: 20px;
    background-position: -72px -468px;
}
.flag-icon-de {
    width: 27px;
    height: 20px;
    background-position: -108px -468px;
}
.flag-icon-cz {
    width: 27px;
    height: 20px;
    background-position: -144px -468px;
}
.flag-icon-cy {
    width: 27px;
    height: 20px;
    background-position: -180px -468px;
}
.flag-icon-cx {
    width: 27px;
    height: 20px;
    background-position: -216px -468px;
}
.flag-icon-cw {
    width: 27px;
    height: 20px;
    background-position: -252px -468px;
}
.flag-icon-cv {
    width: 27px;
    height: 20px;
    background-position: -288px -468px;
}
.flag-icon-cu {
    width: 27px;
    height: 20px;
    background-position: -324px -468px;
}
.flag-icon-cr {
    width: 27px;
    height: 20px;
    background-position: -360px -468px;
}
.flag-icon-co {
    width: 27px;
    height: 20px;
    background-position: -396px -468px;
}
.flag-icon-cn {
    width: 27px;
    height: 20px;
    background-position: -432px -468px;
}
.flag-icon-cm {
    width: 27px;
    height: 20px;
    background-position: -468px -468px;
}
.flag-icon-cl {
    width: 27px;
    height: 20px;
    background-position: -504px -468px;
}
.flag-icon-ck {
    width: 27px;
    height: 20px;
    background-position: 0 -504px;
}
.flag-icon-ci {
    width: 27px;
    height: 20px;
    background-position: -36px -504px;
}
.flag-icon-ch {
    width: 27px;
    height: 20px;
    background-position: -72px -504px;
}
.flag-icon-cg {
    width: 27px;
    height: 20px;
    background-position: -108px -504px;
}
.flag-icon-cf {
    width: 27px;
    height: 20px;
    background-position: -144px -504px;
}
.flag-icon-cd {
    width: 27px;
    height: 20px;
    background-position: -180px -504px;
}
.flag-icon-cc {
    width: 27px;
    height: 20px;
    background-position: -216px -504px;
}
.flag-icon-ca {
    width: 27px;
    height: 20px;
    background-position: -252px -504px;
}
.flag-icon-bz {
    width: 27px;
    height: 20px;
    background-position: -288px -504px;
}
.flag-icon-by {
    width: 27px;
    height: 20px;
    background-position: -324px -504px;
}
.flag-icon-bw {
    width: 27px;
    height: 20px;
    background-position: -360px -504px;
}
.flag-icon-bv {
    width: 27px;
    height: 20px;
    background-position: -396px -504px;
}
.flag-icon-bt {
    width: 27px;
    height: 20px;
    background-position: -432px -504px;
}
.flag-icon-bs {
    width: 27px;
    height: 20px;
    background-position: -468px -504px;
}
.flag-icon-br {
    width: 27px;
    height: 20px;
    background-position: -504px -504px;
}
.flag-icon-bq {
    width: 27px;
    height: 20px;
    background-position: 0 -540px;
}
.flag-icon-bo {
    width: 27px;
    height: 20px;
    background-position: -36px -540px;
}
.flag-icon-bn {
    width: 27px;
    height: 20px;
    background-position: -72px -540px;
}
.flag-icon-bm {
    width: 27px;
    height: 20px;
    background-position: -108px -540px;
}
.flag-icon-bl {
    width: 27px;
    height: 20px;
    background-position: -144px -540px;
}
.flag-icon-bj {
    width: 27px;
    height: 20px;
    background-position: -180px -540px;
}
.flag-icon-bi {
    width: 27px;
    height: 20px;
    background-position: -216px -540px;
}
.flag-icon-bh {
    width: 27px;
    height: 20px;
    background-position: -252px -540px;
}
.flag-icon-bg {
    width: 27px;
    height: 20px;
    background-position: -288px -540px;
}
.flag-icon-bf {
    width: 27px;
    height: 20px;
    background-position: -324px -540px;
}
.flag-icon-be {
    width: 27px;
    height: 20px;
    background-position: -360px -540px;
}
.flag-icon-bd {
    width: 27px;
    height: 20px;
    background-position: -396px -540px;
}
.flag-icon-bb {
    width: 27px;
    height: 20px;
    background-position: -432px -540px;
}
.flag-icon-ba {
    width: 27px;
    height: 20px;
    background-position: -468px -540px;
}
.flag-icon-az {
    width: 27px;
    height: 20px;
    background-position: -504px -540px;
}
.flag-icon-ax {
    width: 27px;
    height: 20px;
    background-position: 0 -576px;
}
.flag-icon-aw {
    width: 27px;
    height: 20px;
    background-position: -36px -576px;
}
.flag-icon-au {
    width: 27px;
    height: 20px;
    background-position: -72px -576px;
}
.flag-icon-at {
    width: 27px;
    height: 20px;
    background-position: -108px -576px;
}
.flag-icon-as {
    width: 27px;
    height: 20px;
    background-position: -144px -576px;
}
.flag-icon-ar {
    width: 27px;
    height: 20px;
    background-position: -180px -576px;
}
.flag-icon-aq {
    width: 27px;
    height: 20px;
    background-position: -216px -576px;
}
.flag-icon-ao {
    width: 27px;
    height: 20px;
    background-position: -252px -576px;
}
.flag-icon-am {
    width: 27px;
    height: 20px;
    background-position: -288px -576px;
}
.flag-icon-al {
    width: 27px;
    height: 20px;
    background-position: -324px -576px;
}
.flag-icon-ai {
    width: 27px;
    height: 20px;
    background-position: -360px -576px;
}
.flag-icon-ag {
    width: 27px;
    height: 20px;
    background-position: -396px -576px;
}
.flag-icon-af {
    width: 27px;
    height: 20px;
    background-position: -432px -576px;
}
.flag-icon-ae {
    width: 27px;
    height: 20px;
    background-position: -468px -576px;
}
.flag-icon-ad {
    width: 27px;
    height: 20px;
    background-position: -504px -576px;
}

