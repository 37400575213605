$BLUE1: #5491E8;
$RED1: #E1224F;
$GREEN1: #219EAB;
$RED2: #E63A20;
$PURPLE1: #52489c;
$BLUE2: #1C5CB6;
$ORANGE1: #FF5802;
$BLUE3: #0f3357;
$RED3: #870b58;
$GREEN2: #126939;

.BLUE1 {
    background-color: $BLUE1;
}
.BLUE2 {
    background-color: $BLUE2;
}
.BLUE3 {
    background-color: $BLUE3;
}
.RED1 {
    background-color: $RED1;
}
.RED2 {
    background-color: $RED2;
}
.RED3 {
    background-color: $RED3;
}
.GREEN1 {
    background-color: $GREEN1;
}
.GREEN2 {
    background-color: $GREEN2;
}
.PURPLE1 {
    background-color: $PURPLE1;
}
.ORANGE1 {
    background-color: $ORANGE1;
}
