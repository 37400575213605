@import "logbook-variables";

@function unicode($str) {
    @return unquote("\"") + $str + unquote("\"");
}
[class^="dln-icon-"], [class*=" dln-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'logbook' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dln-icon-arrow_down {
    &:before {
        content: $dln-icon-arrow_down;
    }
}
.dln-icon-arrow_up {
    &:before {
        content: $dln-icon-arrow_up;
    }
}
.dln-icon-chevron_double {
    &:before {
        content: $dln-icon-chevron_double;
    }
}
.dln-icon-search {
    &:before {
        content: $dln-icon-search;
    }
}
.dln-icon-view {
    &:before {
        content: $dln-icon-view;
    }
}
.dln-icon-write {
    &:before {
        content: $dln-icon-write;
    }
}
.dln-icon-delete {
    &:before {
        content: $dln-icon-delete;
    }
}
.dln-icon-left {
    &:before {
        content: $dln-icon-left;
    }
}
.dln-icon-right {
    &:before {
        content: $dln-icon-right;
    }
}
.dln-icon-left2 {
    &:before {
        content: $dln-icon-left2;
    }
}
.dln-icon-right2 {
    &:before {
        content: $dln-icon-right2;
    }
}
.dln-icon-log-out {
    &:before {
        content: $dln-icon-log-out;
    }
}
.dln-icon-retour {
    &:before {
        content: $dln-icon-retour;
    }
}
.dln-icon-chat {
    &:before {
        content: $dln-icon-chat;
    }
}
.dln-icon-modify {
    &:before {
        content: $dln-icon-modify;
    }
}
.dln-icon-tag {
    &:before {
        content: $dln-icon-tag;
    }
}
.dln-icon-add_mail {
    &:before {
        content: $dln-icon-add_mail;
    }
}
.dln-icon-add_people {
    &:before {
        content: $dln-icon-add_people;
    }
}
.dln-icon-add {
    &:before {
        content: $dln-icon-add;
    }
}
.dln-icon-archive {
    &:before {
        content: $dln-icon-archive;
    }
}
.dln-icon-assign {
    &:before {
        content: $dln-icon-assign;
    }
}
.dln-icon-attachment {
    &:before {
        content: $dln-icon-attachment;
    }
}
.dln-icon-calendar {
    &:before {
        content: $dln-icon-calendar;
    }
}
.dln-icon-close {
    &:before {
        content: $dln-icon-close;
    }
}
.dln-icon-company {
    &:before {
        content: $dln-icon-company;
    }
}
.dln-icon-contact {
    &:before {
        content: $dln-icon-contact;
    }
}
.dln-icon-document_no_signed {
    &:before {
        content: $dln-icon-document_no_signed;
    }
}
.dln-icon-document_pending {
    &:before {
        content: $dln-icon-document_pending;
    }
}
.dln-icon-document_signed {
    &:before {
        content: $dln-icon-document_signed;
    }
}
.dln-icon-download {
    &:before {
        content: $dln-icon-download;
    }
}
.dln-icon-flight {
    &:before {
        content: $dln-icon-flight;
    }
}
.dln-icon-help {
    &:before {
        content: $dln-icon-help;
    }
}
.dln-icon-instructions {
    &:before {
        content: $dln-icon-instructions;
    }
}
.dln-icon-less {
    &:before {
        content: $dln-icon-less;
    }
}
.dln-icon-location {
    &:before {
        content: $dln-icon-location;
    }
}
.dln-icon-logs {
    &:before {
        content: $dln-icon-logs;
    }
}
.dln-icon-mail_answer {
    &:before {
        content: $dln-icon-mail_answer;
    }
}
.dln-icon-mail_foward {
    &:before {
        content: $dln-icon-mail_foward;
    }
}
.dln-icon-mail {
    &:before {
        content: $dln-icon-mail;
    }
}
.dln-icon-mon_logbook {
    &:before {
        content: $dln-icon-mon_logbook;
    }
}
.dln-icon-more {
    &:before {
        content: $dln-icon-more;
    }
}
.dln-icon-no {
    &:before {
        content: $dln-icon-no;
    }
}
.dln-icon-notifications {
    &:before {
        content: $dln-icon-notifications;
    }
}
.dln-icon-pending {
    &:before {
        content: $dln-icon-pending;
    }
}
.dln-icon-people {
    &:before {
        content: $dln-icon-people;
    }
}
.dln-icon-peoples {
    &:before {
        content: $dln-icon-peoples;
    }
}
.dln-icon-see_more {
    &:before {
        content: $dln-icon-see_more;
    }
}
.dln-icon-share {
    &:before {
        content: $dln-icon-share;
    }
}
.dln-icon-support {
    &:before {
        content: $dln-icon-support;
    }
}
.dln-icon-validate {
    &:before {
        content: $dln-icon-validate;
    }
}
.dln-icon-arrow-up-thin {
    &:before {
        content: $dln-icon-arrow-up-thin;
    }
}
.dln-icon-attach {
    &:before {
        content: $dln-icon-attach;
    }
}
.dln-icon-checkbox {
    &:before {
        content: $dln-icon-checkbox;
    }
}
.dln-icon-comment {
    &:before {
        content: $dln-icon-comment;
    }
}
.dln-icon-delete2 {
    &:before {
        content: $dln-icon-delete2;
    }
}
.dln-icon-edit2 {
    &:before {
        content: $dln-icon-edit2;
    }
}
.dln-icon-instruction {
    &:before {
        content: $dln-icon-instruction;
    }
}
.dln-icon-key {
    &:before {
        content: $dln-icon-key;
    }
}
.dln-icon-reload {
    &:before {
        content: $dln-icon-reload;
    }
}
.dln-icon-share2 {
    &:before {
        content: $dln-icon-share2;
    }
}
.dln-icon-tic-success {
    &:before {
        content: $dln-icon-tic-success;
    }
}
.dln-icon-unremove {
    &:before {
        content: $dln-icon-unremove;
    }
}
.dln-icon-write-log {
    &:before {
        content: $dln-icon-write-log;
    }
}
