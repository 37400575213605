@charset "UTF-8";
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("/content/fonts/opensans/open-sans-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-300.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-300.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-300.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"), url("/content/fonts/opensans/open-sans-v14-latin-300italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-300italic.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-300italic.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-300italic.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-300italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/content/fonts/opensans/open-sans-v14-latin-regular.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-regular.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-regular.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-regular.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("/content/fonts/opensans/open-sans-v14-latin-italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-italic.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-italic.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-italic.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("/content/fonts/opensans/open-sans-v14-latin-600.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-600.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-600.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-600.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-600italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"), url("/content/fonts/opensans/open-sans-v14-latin-600italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-600italic.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-600italic.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-600italic.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-600italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/content/fonts/opensans/open-sans-v14-latin-700.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-700.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-700.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-700.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-700italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("/content/fonts/opensans/open-sans-v14-latin-700italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-700italic.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-700italic.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-700italic.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-700italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("/content/fonts/opensans/open-sans-v14-latin-800.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-800.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-800.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-800.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-800.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  font-display: fallback;
  src: url("/content/fonts/opensans/open-sans-v14-latin-800italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"), url("/content/fonts/opensans/open-sans-v14-latin-800italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/opensans/open-sans-v14-latin-800italic.woff2") format("woff2"), url("/content/fonts/opensans/open-sans-v14-latin-800italic.woff") format("woff"), url("/content/fonts/opensans/open-sans-v14-latin-800italic.ttf") format("truetype"), url("/content/fonts/opensans/open-sans-v14-latin-800italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* icomoon */
@font-face {
  font-family: 'linearicons';
  src: url("/content/fonts/linearicons/linearicons.eot?5c471g");
  src: url("/content/fonts/linearicons/linearicons.eot?5c471g#iefix") format("embedded-opentype"), url("/content/fonts/linearicons/linearicons.svg?5c471g#linearicons") format("svg"), url("/content/fonts/linearicons/linearicons.ttf?5c471g") format("truetype"), url("/content/fonts/linearicons/linearicons.woff?5c471g") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'logbook';
  src: url("/content/fonts/logbook/logbook.woff2?e8ycpn") format("woff2"), url("/content/fonts/logbook/logbook.ttf?e8ycpn") format("truetype"), url("/content/fonts/logbook/logbook.woff?e8ycpn") format("woff"), url("/content/fonts/logbook/logbook.svg?e8ycpn#logbook") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/* material design */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/content/fonts/material/material-font.woff2") format("woff2"); }

.custom-on-boarding-modal-panel {
  height: auto !important; }
  @media (max-width: 768px) {
    .custom-on-boarding-modal-panel {
      max-width: 90vw !important;
      width: 90vw !important; }
      .custom-on-boarding-modal-panel .mat-dialog-container .title h2 {
        margin: 0 !important; }
      .custom-on-boarding-modal-panel .mat-dialog-container .subtitle {
        margin: 0 !important;
        letter-spacing: 0.5px; } }

@media (max-width: 768px) {
  .dln-dt-popup {
    height: 100vh;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    background: rgba(0, 0, 0, 0.1); } }

dln-date-time-inline dln-date-time-container {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important; }

dln-date-time-container.dln-dt-popup-container, dln-dialog-container {
  width: 440px; }
  @media (max-width: 768px) {
    dln-date-time-container.dln-dt-popup-container, dln-dialog-container {
      margin: 25% 15px;
      width: calc(100vw - 30px); } }
  @media (max-width: 768px) and (orientation: landscape) {
    dln-date-time-container.dln-dt-popup-container, dln-dialog-container {
      margin: 15px; } }

span[class^="icon-"], span[class*=" icon-"], i[class^="icon-"], i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'linearicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-calendar-full:before {
  content: ""; }

.icon-keyboard:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-earth:before {
  content: ""; }

.icon-cog:before {
  content: ""; }

.icon-magic-wand:before {
  content: ""; }

.icon-hourglass:before {
  content: ""; }

.icon-clock:before {
  content: ""; }

.icon-rocket:before {
  content: ""; }

.icon-phone:before {
  content: ""; }

.icon-heart-pulse:before {
  content: ""; }

.icon-poop:before {
  content: ""; }

.icon-linearicons:before {
  content: ""; }

.icon-wheelchair:before {
  content: ""; }

.icon-mustache:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-license:before {
  content: ""; }

.icon-bullhorn:before {
  content: ""; }

.icon-dinner:before {
  content: ""; }

.icon-unlink:before {
  content: ""; }

.icon-store:before {
  content: ""; }

.icon-thumbs-up:before {
  content: ""; }

.icon-thumbs-down:before {
  content: ""; }

.icon-car:before {
  content: ""; }

.icon-cloud-sync:before {
  content: ""; }

.icon-apartment:before {
  content: ""; }

.icon-sun:before {
  content: ""; }

.icon-bus:before {
  content: ""; }

.icon-dice:before {
  content: ""; }

.icon-database:before {
  content: ""; }

.icon-train:before {
  content: ""; }

.icon-paw:before {
  content: ""; }

.icon-page-break:before {
  content: ""; }

.icon-bug:before {
  content: ""; }

.icon-volume-high:before {
  content: ""; }

.icon-phone-handset:before {
  content: ""; }

.icon-pointer-left:before {
  content: ""; }

.icon-pointer-right:before {
  content: ""; }

.icon-hand:before {
  content: ""; }

.icon-pointer-up:before {
  content: ""; }

.icon-pointer-down:before {
  content: ""; }

.icon-spell-check:before {
  content: ""; }

.icon-printer:before {
  content: ""; }

.icon-users:before {
  content: ""; }

.icon-cloud-download:before {
  content: ""; }

.icon-cloud-upload:before {
  content: ""; }

.icon-pushpin:before {
  content: ""; }

.icon-lighter:before {
  content: ""; }

.icon-graduation-hat:before {
  content: ""; }

.icon-sad:before {
  content: ""; }

.icon-bicycle:before {
  content: ""; }

.icon-leaf:before {
  content: ""; }

.icon-laptop-phone:before {
  content: ""; }

.icon-volume-medium:before {
  content: ""; }

.icon-eye:before {
  content: ""; }

.icon-funnel:before {
  content: ""; }

.icon-list:before {
  content: ""; }

.icon-line-spacing:before {
  content: ""; }

.icon-exit:before {
  content: ""; }

.icon-coffee-cup:before {
  content: ""; }

.icon-enter:before {
  content: ""; }

.icon-sort-amount-asc:before {
  content: ""; }

.icon-trash:before {
  content: ""; }

.icon-cloud-check:before {
  content: ""; }

.icon-smile:before {
  content: ""; }

.icon-sync:before {
  content: ""; }

.icon-indent-decrease:before {
  content: ""; }

.icon-indent-increase:before {
  content: ""; }

.icon-flag:before {
  content: ""; }

.icon-sort-alpha-asc:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-move:before {
  content: ""; }

.icon-select:before {
  content: ""; }

.icon-music-note:before {
  content: ""; }

.icon-text-size:before {
  content: ""; }

.icon-alarm:before {
  content: ""; }

.icon-neutral:before {
  content: ""; }

.icon-gift:before {
  content: ""; }

.icon-history:before {
  content: ""; }

.icon-layers:before {
  content: ""; }

.icon-bubble:before {
  content: ""; }

.icon-text-format-remove:before {
  content: ""; }

.icon-star:before {
  content: ""; }

.icon-heart:before {
  content: ""; }

.icon-camera:before {
  content: ""; }

.icon-cross-circle:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-question-circle:before {
  content: ""; }

.icon-picture:before {
  content: ""; }

.icon-shirt:before {
  content: ""; }

.icon-chart-bars:before {
  content: ""; }

.icon-bold:before {
  content: ""; }

.icon-tag:before {
  content: ""; }

.icon-frame-contract:before {
  content: ""; }

.icon-frame-expand:before {
  content: ""; }

.icon-inbox:before {
  content: ""; }

.icon-cloud:before {
  content: ""; }

.icon-menu-circle:before {
  content: ""; }

.icon-volume-low:before {
  content: ""; }

.icon-briefcase:before {
  content: ""; }

.icon-moon:before {
  content: ""; }

.icon-code:before {
  content: ""; }

.icon-camera-video:before {
  content: ""; }

.icon-highlight:before {
  content: ""; }

.icon-user:before {
  content: ""; }

.icon-home:before {
  content: ""; }

.icon-map-marker:before {
  content: ""; }

.icon-text-align-center:before {
  content: ""; }

.icon-text-align-justify:before {
  content: ""; }

.icon-text-align-left:before {
  content: ""; }

.icon-text-align-right:before {
  content: ""; }

.icon-enter-down:before {
  content: ""; }

.icon-exit-up:before {
  content: ""; }

.icon-diamond:before {
  content: ""; }

.icon-file-add:before {
  content: ""; }

.icon-construction:before {
  content: ""; }

.icon-arrow-right-circle:before {
  content: ""; }

.icon-arrow-down-circle:before {
  content: ""; }

.icon-arrow-left-circle:before {
  content: ""; }

.icon-arrow-up-circle:before {
  content: ""; }

.icon-power-switch:before {
  content: ""; }

.icon-crop:before {
  content: ""; }

.icon-pencil:before {
  content: ""; }

.icon-film-play:before {
  content: ""; }

.icon-pie-chart:before {
  content: ""; }

.icon-checkmark-circle:before {
  content: ""; }

.icon-chevron-right-circle:before {
  content: ""; }

.icon-tablet:before {
  content: ""; }

.icon-direction-ltr:before {
  content: ""; }

.icon-chevron-down-circle:before {
  content: ""; }

.icon-chevron-left-circle:before {
  content: ""; }

.icon-chevron-up-circle:before {
  content: ""; }

.icon-plus-circle:before {
  content: ""; }

.icon-direction-rtl:before {
  content: ""; }

.icon-smartphone:before {
  content: ""; }

.icon-book:before {
  content: ""; }

.icon-mic:before {
  content: ""; }

.icon-text-format:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-drop:before {
  content: ""; }

.icon-upload:before {
  content: ""; }

.icon-warning:before {
  content: ""; }

.icon-map:before {
  content: ""; }

.icon-redo:before {
  content: ""; }

.icon-undo:before {
  content: ""; }

.icon-laptop:before {
  content: ""; }

.icon-strikethrough:before {
  content: ""; }

.icon-circle-minus:before {
  content: ""; }

.icon-magnifier:before {
  content: ""; }

.icon-envelope:before {
  content: ""; }

.icon-cross:before {
  content: ""; }

.icon-volume:before {
  content: ""; }

.icon-file-empty:before {
  content: ""; }

.icon-paperclip:before {
  content: ""; }

.icon-menu:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-bookmark:before {
  content: ""; }

.icon-screen:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-underline:before {
  content: ""; }

.icon-italic:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-pilcrow:before {
  content: ""; }

.icon-chevron-left:before {
  content: ""; }

.icon-chevron-right:before {
  content: ""; }

.icon-chevron-up:before {
  content: ""; }

.icon-chevron-down:before {
  content: ""; }

[class^="dln-icon-"], [class*=" dln-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'logbook' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dln-icon-arrow_down:before {
  content: ""; }

.dln-icon-arrow_up:before {
  content: ""; }

.dln-icon-chevron_double:before {
  content: ""; }

.dln-icon-search:before {
  content: ""; }

.dln-icon-view:before {
  content: ""; }

.dln-icon-write:before {
  content: ""; }

.dln-icon-delete:before {
  content: ""; }

.dln-icon-left:before {
  content: ""; }

.dln-icon-right:before {
  content: ""; }

.dln-icon-left2:before {
  content: ""; }

.dln-icon-right2:before {
  content: ""; }

.dln-icon-log-out:before {
  content: ""; }

.dln-icon-retour:before {
  content: ""; }

.dln-icon-chat:before {
  content: ""; }

.dln-icon-modify:before {
  content: ""; }

.dln-icon-tag:before {
  content: ""; }

.dln-icon-add_mail:before {
  content: ""; }

.dln-icon-add_people:before {
  content: ""; }

.dln-icon-add:before {
  content: ""; }

.dln-icon-archive:before {
  content: ""; }

.dln-icon-assign:before {
  content: ""; }

.dln-icon-attachment:before {
  content: ""; }

.dln-icon-calendar:before {
  content: ""; }

.dln-icon-close:before {
  content: ""; }

.dln-icon-company:before {
  content: ""; }

.dln-icon-contact:before {
  content: ""; }

.dln-icon-document_no_signed:before {
  content: ""; }

.dln-icon-document_pending:before {
  content: ""; }

.dln-icon-document_signed:before {
  content: ""; }

.dln-icon-download:before {
  content: ""; }

.dln-icon-flight:before {
  content: ""; }

.dln-icon-help:before {
  content: ""; }

.dln-icon-instructions:before {
  content: ""; }

.dln-icon-less:before {
  content: ""; }

.dln-icon-location:before {
  content: ""; }

.dln-icon-logs:before {
  content: ""; }

.dln-icon-mail_answer:before {
  content: ""; }

.dln-icon-mail_foward:before {
  content: ""; }

.dln-icon-mail:before {
  content: ""; }

.dln-icon-mon_logbook:before {
  content: ""; }

.dln-icon-more:before {
  content: ""; }

.dln-icon-no:before {
  content: ""; }

.dln-icon-notifications:before {
  content: ""; }

.dln-icon-pending:before {
  content: ""; }

.dln-icon-people:before {
  content: ""; }

.dln-icon-peoples:before {
  content: ""; }

.dln-icon-see_more:before {
  content: ""; }

.dln-icon-share:before {
  content: ""; }

.dln-icon-support:before {
  content: ""; }

.dln-icon-validate:before {
  content: ""; }

.dln-icon-arrow-up-thin:before {
  content: ""; }

.dln-icon-attach:before {
  content: ""; }

.dln-icon-checkbox:before {
  content: ""; }

.dln-icon-comment:before {
  content: ""; }

.dln-icon-delete2:before {
  content: ""; }

.dln-icon-edit2:before {
  content: ""; }

.dln-icon-instruction:before {
  content: ""; }

.dln-icon-key:before {
  content: ""; }

.dln-icon-reload:before {
  content: ""; }

.dln-icon-share2:before {
  content: ""; }

.dln-icon-tic-success:before {
  content: ""; }

.dln-icon-unremove:before {
  content: ""; }

.dln-icon-write-log:before {
  content: ""; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.flag-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("/content/fonts/flags/sprite.svg"); }

.flag-icon-zw {
  width: 27px;
  height: 20px;
  background-position: 0 0; }

.flag-icon-zm {
  width: 27px;
  height: 20px;
  background-position: -36px 0; }

.flag-icon-za {
  width: 27px;
  height: 20px;
  background-position: -72px 0; }

.flag-icon-yt {
  width: 27px;
  height: 20px;
  background-position: -108px 0; }

.flag-icon-ye {
  width: 27px;
  height: 20px;
  background-position: -144px 0; }

.flag-icon-ws {
  width: 27px;
  height: 20px;
  background-position: -180px 0; }

.flag-icon-wf {
  width: 27px;
  height: 20px;
  background-position: -216px 0; }

.flag-icon-vu {
  width: 27px;
  height: 20px;
  background-position: -252px 0; }

.flag-icon-vn {
  width: 27px;
  height: 20px;
  background-position: -288px 0; }

.flag-icon-vi {
  width: 27px;
  height: 20px;
  background-position: -324px 0; }

.flag-icon-vg {
  width: 27px;
  height: 20px;
  background-position: -360px 0; }

.flag-icon-ve {
  width: 27px;
  height: 20px;
  background-position: -396px 0; }

.flag-icon-vc {
  width: 27px;
  height: 20px;
  background-position: -432px 0; }

.flag-icon-va {
  width: 27px;
  height: 20px;
  background-position: -468px 0; }

.flag-icon-uz {
  width: 27px;
  height: 20px;
  background-position: -504px 0; }

.flag-icon-uy {
  width: 27px;
  height: 20px;
  background-position: 0 -36px; }

.flag-icon-us {
  width: 27px;
  height: 20px;
  background-position: -36px -36px; }

.flag-icon-un {
  width: 27px;
  height: 20px;
  background-position: -72px -36px; }

.flag-icon-um {
  width: 27px;
  height: 20px;
  background-position: -108px -36px; }

.flag-icon-ug {
  width: 27px;
  height: 20px;
  background-position: -144px -36px; }

.flag-icon-ua {
  width: 27px;
  height: 20px;
  background-position: -180px -36px; }

.flag-icon-tz {
  width: 27px;
  height: 20px;
  background-position: -216px -36px; }

.flag-icon-tw {
  width: 27px;
  height: 20px;
  background-position: -252px -36px; }

.flag-icon-tv {
  width: 27px;
  height: 20px;
  background-position: -288px -36px; }

.flag-icon-tt {
  width: 27px;
  height: 20px;
  background-position: -324px -36px; }

.flag-icon-tr {
  width: 27px;
  height: 20px;
  background-position: -360px -36px; }

.flag-icon-to {
  width: 27px;
  height: 20px;
  background-position: -396px -36px; }

.flag-icon-tn {
  width: 27px;
  height: 20px;
  background-position: -432px -36px; }

.flag-icon-tm {
  width: 27px;
  height: 20px;
  background-position: -468px -36px; }

.flag-icon-tl {
  width: 27px;
  height: 20px;
  background-position: -504px -36px; }

.flag-icon-tk {
  width: 27px;
  height: 20px;
  background-position: 0 -72px; }

.flag-icon-tj {
  width: 27px;
  height: 20px;
  background-position: -36px -72px; }

.flag-icon-th {
  width: 27px;
  height: 20px;
  background-position: -72px -72px; }

.flag-icon-tg {
  width: 27px;
  height: 20px;
  background-position: -108px -72px; }

.flag-icon-tf {
  width: 27px;
  height: 20px;
  background-position: -144px -72px; }

.flag-icon-td {
  width: 27px;
  height: 20px;
  background-position: -180px -72px; }

.flag-icon-tc {
  width: 27px;
  height: 20px;
  background-position: -216px -72px; }

.flag-icon-sz {
  width: 27px;
  height: 20px;
  background-position: -252px -72px; }

.flag-icon-sy {
  width: 27px;
  height: 20px;
  background-position: -288px -72px; }

.flag-icon-sx {
  width: 27px;
  height: 20px;
  background-position: -324px -72px; }

.flag-icon-sv {
  width: 27px;
  height: 20px;
  background-position: -360px -72px; }

.flag-icon-st {
  width: 27px;
  height: 20px;
  background-position: -396px -72px; }

.flag-icon-ss {
  width: 27px;
  height: 20px;
  background-position: -432px -72px; }

.flag-icon-sr {
  width: 27px;
  height: 20px;
  background-position: -468px -72px; }

.flag-icon-so {
  width: 27px;
  height: 20px;
  background-position: -504px -72px; }

.flag-icon-sn {
  width: 27px;
  height: 20px;
  background-position: 0 -108px; }

.flag-icon-sm {
  width: 27px;
  height: 20px;
  background-position: -36px -108px; }

.flag-icon-sl {
  width: 27px;
  height: 20px;
  background-position: -72px -108px; }

.flag-icon-sk {
  width: 27px;
  height: 20px;
  background-position: -108px -108px; }

.flag-icon-sj {
  width: 27px;
  height: 20px;
  background-position: -144px -108px; }

.flag-icon-si {
  width: 27px;
  height: 20px;
  background-position: -180px -108px; }

.flag-icon-sh {
  width: 27px;
  height: 20px;
  background-position: -216px -108px; }

.flag-icon-sg {
  width: 27px;
  height: 20px;
  background-position: -252px -108px; }

.flag-icon-se {
  width: 27px;
  height: 20px;
  background-position: -288px -108px; }

.flag-icon-sd {
  width: 27px;
  height: 20px;
  background-position: -324px -108px; }

.flag-icon-sc {
  width: 27px;
  height: 20px;
  background-position: -360px -108px; }

.flag-icon-sb {
  width: 27px;
  height: 20px;
  background-position: -396px -108px; }

.flag-icon-sa {
  width: 27px;
  height: 20px;
  background-position: -432px -108px; }

.flag-icon-rw {
  width: 27px;
  height: 20px;
  background-position: -468px -108px; }

.flag-icon-ru {
  width: 27px;
  height: 20px;
  background-position: -504px -108px; }

.flag-icon-rs {
  width: 27px;
  height: 20px;
  background-position: 0 -144px; }

.flag-icon-ro {
  width: 27px;
  height: 20px;
  background-position: -36px -144px; }

.flag-icon-re {
  width: 27px;
  height: 20px;
  background-position: -72px -144px; }

.flag-icon-qa {
  width: 27px;
  height: 20px;
  background-position: -108px -144px; }

.flag-icon-py {
  width: 27px;
  height: 20px;
  background-position: -144px -144px; }

.flag-icon-pw {
  width: 27px;
  height: 20px;
  background-position: -180px -144px; }

.flag-icon-pt {
  width: 27px;
  height: 20px;
  background-position: -216px -144px; }

.flag-icon-ps {
  width: 27px;
  height: 20px;
  background-position: -252px -144px; }

.flag-icon-pr {
  width: 27px;
  height: 20px;
  background-position: -288px -144px; }

.flag-icon-pn {
  width: 27px;
  height: 20px;
  background-position: -324px -144px; }

.flag-icon-pm {
  width: 27px;
  height: 20px;
  background-position: -360px -144px; }

.flag-icon-pl {
  width: 27px;
  height: 20px;
  background-position: -396px -144px; }

.flag-icon-pk {
  width: 27px;
  height: 20px;
  background-position: -432px -144px; }

.flag-icon-ph {
  width: 27px;
  height: 20px;
  background-position: -468px -144px; }

.flag-icon-pg {
  width: 27px;
  height: 20px;
  background-position: -504px -144px; }

.flag-icon-pf {
  width: 27px;
  height: 20px;
  background-position: 0 -180px; }

.flag-icon-pe {
  width: 27px;
  height: 20px;
  background-position: -36px -180px; }

.flag-icon-pa {
  width: 27px;
  height: 20px;
  background-position: -72px -180px; }

.flag-icon-om {
  width: 27px;
  height: 20px;
  background-position: -108px -180px; }

.flag-icon-nz {
  width: 27px;
  height: 20px;
  background-position: -144px -180px; }

.flag-icon-nu {
  width: 27px;
  height: 20px;
  background-position: -180px -180px; }

.flag-icon-nr {
  width: 27px;
  height: 20px;
  background-position: -216px -180px; }

.flag-icon-np {
  width: 27px;
  height: 20px;
  background-position: -252px -180px; }

.flag-icon-no {
  width: 27px;
  height: 20px;
  background-position: -288px -180px; }

.flag-icon-nl {
  width: 27px;
  height: 20px;
  background-position: -324px -180px; }

.flag-icon-ni {
  width: 27px;
  height: 20px;
  background-position: -360px -180px; }

.flag-icon-ng {
  width: 27px;
  height: 20px;
  background-position: -396px -180px; }

.flag-icon-nf {
  width: 27px;
  height: 20px;
  background-position: -432px -180px; }

.flag-icon-ne {
  width: 27px;
  height: 20px;
  background-position: -468px -180px; }

.flag-icon-nc {
  width: 27px;
  height: 20px;
  background-position: -504px -180px; }

.flag-icon-na {
  width: 27px;
  height: 20px;
  background-position: 0 -216px; }

.flag-icon-mz {
  width: 27px;
  height: 20px;
  background-position: -36px -216px; }

.flag-icon-my {
  width: 27px;
  height: 20px;
  background-position: -72px -216px; }

.flag-icon-mx {
  width: 27px;
  height: 20px;
  background-position: -108px -216px; }

.flag-icon-mw {
  width: 27px;
  height: 20px;
  background-position: -144px -216px; }

.flag-icon-mv {
  width: 27px;
  height: 20px;
  background-position: -180px -216px; }

.flag-icon-mu {
  width: 27px;
  height: 20px;
  background-position: -216px -216px; }

.flag-icon-mt {
  width: 27px;
  height: 20px;
  background-position: -252px -216px; }

.flag-icon-ms {
  width: 27px;
  height: 20px;
  background-position: -288px -216px; }

.flag-icon-mr {
  width: 27px;
  height: 20px;
  background-position: -324px -216px; }

.flag-icon-mq {
  width: 27px;
  height: 20px;
  background-position: -360px -216px; }

.flag-icon-mp {
  width: 27px;
  height: 20px;
  background-position: -396px -216px; }

.flag-icon-mo {
  width: 27px;
  height: 20px;
  background-position: -432px -216px; }

.flag-icon-mn {
  width: 27px;
  height: 20px;
  background-position: -468px -216px; }

.flag-icon-mm {
  width: 27px;
  height: 20px;
  background-position: -504px -216px; }

.flag-icon-ml {
  width: 27px;
  height: 20px;
  background-position: 0 -252px; }

.flag-icon-mk {
  width: 27px;
  height: 20px;
  background-position: -36px -252px; }

.flag-icon-mh {
  width: 27px;
  height: 20px;
  background-position: -72px -252px; }

.flag-icon-mg {
  width: 27px;
  height: 20px;
  background-position: -108px -252px; }

.flag-icon-mf {
  width: 27px;
  height: 20px;
  background-position: -144px -252px; }

.flag-icon-me {
  width: 27px;
  height: 20px;
  background-position: -180px -252px; }

.flag-icon-md {
  width: 27px;
  height: 20px;
  background-position: -216px -252px; }

.flag-icon-mc {
  width: 27px;
  height: 20px;
  background-position: -252px -252px; }

.flag-icon-ma {
  width: 27px;
  height: 20px;
  background-position: -288px -252px; }

.flag-icon-ly {
  width: 27px;
  height: 20px;
  background-position: -324px -252px; }

.flag-icon-lv {
  width: 27px;
  height: 20px;
  background-position: -360px -252px; }

.flag-icon-lu {
  width: 27px;
  height: 20px;
  background-position: -396px -252px; }

.flag-icon-lt {
  width: 27px;
  height: 20px;
  background-position: -432px -252px; }

.flag-icon-ls {
  width: 27px;
  height: 20px;
  background-position: -468px -252px; }

.flag-icon-lr {
  width: 27px;
  height: 20px;
  background-position: -504px -252px; }

.flag-icon-lk {
  width: 27px;
  height: 20px;
  background-position: 0 -288px; }

.flag-icon-li {
  width: 27px;
  height: 20px;
  background-position: -36px -288px; }

.flag-icon-lc {
  width: 27px;
  height: 20px;
  background-position: -72px -288px; }

.flag-icon-lb {
  width: 27px;
  height: 20px;
  background-position: -108px -288px; }

.flag-icon-la {
  width: 27px;
  height: 20px;
  background-position: -144px -288px; }

.flag-icon-kz {
  width: 27px;
  height: 20px;
  background-position: -180px -288px; }

.flag-icon-ky {
  width: 27px;
  height: 20px;
  background-position: -216px -288px; }

.flag-icon-kw {
  width: 27px;
  height: 20px;
  background-position: -252px -288px; }

.flag-icon-kr {
  width: 27px;
  height: 20px;
  background-position: -288px -288px; }

.flag-icon-kp {
  width: 27px;
  height: 20px;
  background-position: -324px -288px; }

.flag-icon-kn {
  width: 27px;
  height: 20px;
  background-position: -360px -288px; }

.flag-icon-km {
  width: 27px;
  height: 20px;
  background-position: -396px -288px; }

.flag-icon-ki {
  width: 27px;
  height: 20px;
  background-position: -432px -288px; }

.flag-icon-kh {
  width: 27px;
  height: 20px;
  background-position: -468px -288px; }

.flag-icon-kg {
  width: 27px;
  height: 20px;
  background-position: -504px -288px; }

.flag-icon-ke {
  width: 27px;
  height: 20px;
  background-position: 0 -324px; }

.flag-icon-jp {
  width: 27px;
  height: 20px;
  background-position: -36px -324px; }

.flag-icon-jo {
  width: 27px;
  height: 20px;
  background-position: -72px -324px; }

.flag-icon-jm {
  width: 27px;
  height: 20px;
  background-position: -108px -324px; }

.flag-icon-je {
  width: 27px;
  height: 20px;
  background-position: -144px -324px; }

.flag-icon-it {
  width: 27px;
  height: 20px;
  background-position: -180px -324px; }

.flag-icon-is {
  width: 27px;
  height: 20px;
  background-position: -216px -324px; }

.flag-icon-ir {
  width: 27px;
  height: 20px;
  background-position: -252px -324px; }

.flag-icon-iq {
  width: 27px;
  height: 20px;
  background-position: -288px -324px; }

.flag-icon-io {
  width: 27px;
  height: 20px;
  background-position: -324px -324px; }

.flag-icon-in {
  width: 27px;
  height: 20px;
  background-position: -360px -324px; }

.flag-icon-im {
  width: 27px;
  height: 20px;
  background-position: -396px -324px; }

.flag-icon-il {
  width: 27px;
  height: 20px;
  background-position: -432px -324px; }

.flag-icon-ie {
  width: 27px;
  height: 20px;
  background-position: -468px -324px; }

.flag-icon-id {
  width: 27px;
  height: 20px;
  background-position: -504px -324px; }

.flag-icon-hu {
  width: 27px;
  height: 20px;
  background-position: 0 -360px; }

.flag-icon-ht {
  width: 27px;
  height: 20px;
  background-position: -36px -360px; }

.flag-icon-hr {
  width: 27px;
  height: 20px;
  background-position: -72px -360px; }

.flag-icon-hn {
  width: 27px;
  height: 20px;
  background-position: -108px -360px; }

.flag-icon-hm {
  width: 27px;
  height: 20px;
  background-position: -144px -360px; }

.flag-icon-hk {
  width: 27px;
  height: 20px;
  background-position: -180px -360px; }

.flag-icon-gy {
  width: 27px;
  height: 20px;
  background-position: -216px -360px; }

.flag-icon-gw {
  width: 27px;
  height: 20px;
  background-position: -252px -360px; }

.flag-icon-gu {
  width: 27px;
  height: 20px;
  background-position: -288px -360px; }

.flag-icon-gt {
  width: 27px;
  height: 20px;
  background-position: -324px -360px; }

.flag-icon-gs {
  width: 27px;
  height: 20px;
  background-position: -360px -360px; }

.flag-icon-gr {
  width: 27px;
  height: 20px;
  background-position: -396px -360px; }

.flag-icon-gq {
  width: 27px;
  height: 20px;
  background-position: -432px -360px; }

.flag-icon-gp {
  width: 27px;
  height: 20px;
  background-position: -468px -360px; }

.flag-icon-gn {
  width: 27px;
  height: 20px;
  background-position: -504px -360px; }

.flag-icon-gm {
  width: 27px;
  height: 20px;
  background-position: 0 -396px; }

.flag-icon-gl {
  width: 27px;
  height: 20px;
  background-position: -36px -396px; }

.flag-icon-gi {
  width: 27px;
  height: 20px;
  background-position: -72px -396px; }

.flag-icon-gh {
  width: 27px;
  height: 20px;
  background-position: -108px -396px; }

.flag-icon-gg {
  width: 27px;
  height: 20px;
  background-position: -144px -396px; }

.flag-icon-gf {
  width: 27px;
  height: 20px;
  background-position: -180px -396px; }

.flag-icon-ge {
  width: 27px;
  height: 20px;
  background-position: -216px -396px; }

.flag-icon-gd {
  width: 27px;
  height: 20px;
  background-position: -252px -396px; }

.flag-icon-gb {
  width: 27px;
  height: 20px;
  background-position: -288px -396px; }

.flag-icon-gb-wls {
  width: 27px;
  height: 20px;
  background-position: -324px -396px; }

.flag-icon-gb-sct {
  width: 27px;
  height: 20px;
  background-position: -360px -396px; }

.flag-icon-gb-nir {
  width: 27px;
  height: 20px;
  background-position: -396px -396px; }

.flag-icon-gb-eng {
  width: 27px;
  height: 20px;
  background-position: -432px -396px; }

.flag-icon-ga {
  width: 27px;
  height: 20px;
  background-position: -468px -396px; }

.flag-icon-fr {
  width: 27px;
  height: 20px;
  background-position: -504px -396px; }

.flag-icon-fo {
  width: 27px;
  height: 20px;
  background-position: 0 -432px; }

.flag-icon-fm {
  width: 27px;
  height: 20px;
  background-position: -36px -432px; }

.flag-icon-fk {
  width: 27px;
  height: 20px;
  background-position: -72px -432px; }

.flag-icon-fj {
  width: 27px;
  height: 20px;
  background-position: -108px -432px; }

.flag-icon-fi {
  width: 27px;
  height: 20px;
  background-position: -144px -432px; }

.flag-icon-eu {
  width: 27px;
  height: 20px;
  background-position: -180px -432px; }

.flag-icon-et {
  width: 27px;
  height: 20px;
  background-position: -216px -432px; }

.flag-icon-es {
  width: 27px;
  height: 20px;
  background-position: -252px -432px; }

.flag-icon-er {
  width: 27px;
  height: 20px;
  background-position: -288px -432px; }

.flag-icon-eh {
  width: 27px;
  height: 20px;
  background-position: -324px -432px; }

.flag-icon-eg {
  width: 27px;
  height: 20px;
  background-position: -360px -432px; }

.flag-icon-ee {
  width: 27px;
  height: 20px;
  background-position: -396px -432px; }

.flag-icon-ec {
  width: 27px;
  height: 20px;
  background-position: -432px -432px; }

.flag-icon-dz {
  width: 27px;
  height: 20px;
  background-position: -468px -432px; }

.flag-icon-do {
  width: 27px;
  height: 20px;
  background-position: -504px -432px; }

.flag-icon-dm {
  width: 27px;
  height: 20px;
  background-position: 0 -468px; }

.flag-icon-dk {
  width: 27px;
  height: 20px;
  background-position: -36px -468px; }

.flag-icon-dj {
  width: 27px;
  height: 20px;
  background-position: -72px -468px; }

.flag-icon-de {
  width: 27px;
  height: 20px;
  background-position: -108px -468px; }

.flag-icon-cz {
  width: 27px;
  height: 20px;
  background-position: -144px -468px; }

.flag-icon-cy {
  width: 27px;
  height: 20px;
  background-position: -180px -468px; }

.flag-icon-cx {
  width: 27px;
  height: 20px;
  background-position: -216px -468px; }

.flag-icon-cw {
  width: 27px;
  height: 20px;
  background-position: -252px -468px; }

.flag-icon-cv {
  width: 27px;
  height: 20px;
  background-position: -288px -468px; }

.flag-icon-cu {
  width: 27px;
  height: 20px;
  background-position: -324px -468px; }

.flag-icon-cr {
  width: 27px;
  height: 20px;
  background-position: -360px -468px; }

.flag-icon-co {
  width: 27px;
  height: 20px;
  background-position: -396px -468px; }

.flag-icon-cn {
  width: 27px;
  height: 20px;
  background-position: -432px -468px; }

.flag-icon-cm {
  width: 27px;
  height: 20px;
  background-position: -468px -468px; }

.flag-icon-cl {
  width: 27px;
  height: 20px;
  background-position: -504px -468px; }

.flag-icon-ck {
  width: 27px;
  height: 20px;
  background-position: 0 -504px; }

.flag-icon-ci {
  width: 27px;
  height: 20px;
  background-position: -36px -504px; }

.flag-icon-ch {
  width: 27px;
  height: 20px;
  background-position: -72px -504px; }

.flag-icon-cg {
  width: 27px;
  height: 20px;
  background-position: -108px -504px; }

.flag-icon-cf {
  width: 27px;
  height: 20px;
  background-position: -144px -504px; }

.flag-icon-cd {
  width: 27px;
  height: 20px;
  background-position: -180px -504px; }

.flag-icon-cc {
  width: 27px;
  height: 20px;
  background-position: -216px -504px; }

.flag-icon-ca {
  width: 27px;
  height: 20px;
  background-position: -252px -504px; }

.flag-icon-bz {
  width: 27px;
  height: 20px;
  background-position: -288px -504px; }

.flag-icon-by {
  width: 27px;
  height: 20px;
  background-position: -324px -504px; }

.flag-icon-bw {
  width: 27px;
  height: 20px;
  background-position: -360px -504px; }

.flag-icon-bv {
  width: 27px;
  height: 20px;
  background-position: -396px -504px; }

.flag-icon-bt {
  width: 27px;
  height: 20px;
  background-position: -432px -504px; }

.flag-icon-bs {
  width: 27px;
  height: 20px;
  background-position: -468px -504px; }

.flag-icon-br {
  width: 27px;
  height: 20px;
  background-position: -504px -504px; }

.flag-icon-bq {
  width: 27px;
  height: 20px;
  background-position: 0 -540px; }

.flag-icon-bo {
  width: 27px;
  height: 20px;
  background-position: -36px -540px; }

.flag-icon-bn {
  width: 27px;
  height: 20px;
  background-position: -72px -540px; }

.flag-icon-bm {
  width: 27px;
  height: 20px;
  background-position: -108px -540px; }

.flag-icon-bl {
  width: 27px;
  height: 20px;
  background-position: -144px -540px; }

.flag-icon-bj {
  width: 27px;
  height: 20px;
  background-position: -180px -540px; }

.flag-icon-bi {
  width: 27px;
  height: 20px;
  background-position: -216px -540px; }

.flag-icon-bh {
  width: 27px;
  height: 20px;
  background-position: -252px -540px; }

.flag-icon-bg {
  width: 27px;
  height: 20px;
  background-position: -288px -540px; }

.flag-icon-bf {
  width: 27px;
  height: 20px;
  background-position: -324px -540px; }

.flag-icon-be {
  width: 27px;
  height: 20px;
  background-position: -360px -540px; }

.flag-icon-bd {
  width: 27px;
  height: 20px;
  background-position: -396px -540px; }

.flag-icon-bb {
  width: 27px;
  height: 20px;
  background-position: -432px -540px; }

.flag-icon-ba {
  width: 27px;
  height: 20px;
  background-position: -468px -540px; }

.flag-icon-az {
  width: 27px;
  height: 20px;
  background-position: -504px -540px; }

.flag-icon-ax {
  width: 27px;
  height: 20px;
  background-position: 0 -576px; }

.flag-icon-aw {
  width: 27px;
  height: 20px;
  background-position: -36px -576px; }

.flag-icon-au {
  width: 27px;
  height: 20px;
  background-position: -72px -576px; }

.flag-icon-at {
  width: 27px;
  height: 20px;
  background-position: -108px -576px; }

.flag-icon-as {
  width: 27px;
  height: 20px;
  background-position: -144px -576px; }

.flag-icon-ar {
  width: 27px;
  height: 20px;
  background-position: -180px -576px; }

.flag-icon-aq {
  width: 27px;
  height: 20px;
  background-position: -216px -576px; }

.flag-icon-ao {
  width: 27px;
  height: 20px;
  background-position: -252px -576px; }

.flag-icon-am {
  width: 27px;
  height: 20px;
  background-position: -288px -576px; }

.flag-icon-al {
  width: 27px;
  height: 20px;
  background-position: -324px -576px; }

.flag-icon-ai {
  width: 27px;
  height: 20px;
  background-position: -360px -576px; }

.flag-icon-ag {
  width: 27px;
  height: 20px;
  background-position: -396px -576px; }

.flag-icon-af {
  width: 27px;
  height: 20px;
  background-position: -432px -576px; }

.flag-icon-ae {
  width: 27px;
  height: 20px;
  background-position: -468px -576px; }

.flag-icon-ad {
  width: 27px;
  height: 20px;
  background-position: -504px -576px; }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
mat-option .mat-option-text {
  display: block; }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card-title {
  font-size: 24px;
  font-weight: 500; }

.mat-card-header .mat-card-title {
  font-size: 20px; }

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px; }

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 14px;
  font-weight: 500; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75); } }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px; }

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-sub-label-error {
  font-weight: normal; }

.mat-step-label-error {
  font-size: 14px; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0; }

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px; }

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-base .mat-list-item {
  font-size: 16px; }
  .mat-list-base .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-list-option {
  font-size: 16px; }
  .mat-list-base .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-list-base[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden;
  position: relative; }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple-element {
      display: none; } }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {
  /*!*/ }

@keyframes cdk-text-field-autofill-end {
  /*!*/ }

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1982ee; }

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #54d2df; }

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e63a20; }

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .mat-pseudo-checkbox::after {
    color: #fafafa; }

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #54d2df; }

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1982ee; }

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e63a20; }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-autocomplete-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.mat-badge-content {
  color: white;
  background: #1982ee; }

.mat-badge-accent .mat-badge-content {
  background: #54d2df;
  color: #54d2df; }

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #e63a20; }

.mat-badge {
  position: relative; }

.mat-badge-hidden .mat-badge-content {
  display: none; }

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38); }

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mat-badge-content.mat-badge-active {
  transform: none; }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-small .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-medium .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-large .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #1982ee; }
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #54d2df; }
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #e63a20; }
  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #1982ee; }
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: #54d2df; }
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: #e63a20; }
  .mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
    opacity: 0.1;
    background-color: currentColor; }

.mat-button-focus-overlay {
  background: black; }

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12); }

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: #54d2df; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #1982ee; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #54d2df; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #e63a20; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(84, 210, 223, 0.1); }
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.mat-stroked-button:not([class*='mat-elevation-z']), .mat-flat-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([class*='mat-elevation-z']), .mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']), .mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-fab[disabled]:not([class*='mat-elevation-z']), .mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none; }

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white; }
  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: black; }

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12); }

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87); }

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee; }
  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background: white; }
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12); }

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-checkmark {
  fill: #fafafa; }

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media screen and (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.mat-checkbox-mixedmark {
  background-color: #fafafa; }

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1982ee; }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #54d2df; }

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e63a20; }

.mat-checkbox-disabled.mat-checkbox-checked:not(.mat-checkbox-indeterminate) .mat-checkbox-background {
  background-color: #b0b0b0; }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.mat-checkbox-disabled .mat-checkbox-label {
  color: #b0b0b0; }

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5; } }

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none; } }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: #1982ee; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #54d2df; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: #e63a20; }

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
    opacity: 0.54; }
  .mat-chip.mat-standard-chip.mat-chip-disabled {
    opacity: 0.4; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1982ee;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e63a20;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #54d2df;
  color: #54d2df; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: #54d2df;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
    background: rgba(84, 210, 223, 0.1); }

.mat-table {
  background: white; }

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit; }

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.mat-calendar-body-selected {
  background-color: #1982ee;
  color: white; }

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(25, 130, 238, 0.4); }

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #54d2df;
    color: #54d2df; }
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(84, 210, 223, 0.4); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #54d2df; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #e63a20;
    color: white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(230, 58, 32, 0.4); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-datepicker-toggle-active {
  color: #1982ee; }
  .mat-datepicker-toggle-active.mat-accent {
    color: #54d2df; }
  .mat-datepicker-toggle-active.mat-warn {
    color: #e63a20; }

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
.mat-expansion-panel-header:hover {
    background: white; } }

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #1982ee; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #54d2df; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #e63a20; }

.mat-focused .mat-form-field-required-marker {
  color: #54d2df; }

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1982ee; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #54d2df; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #e63a20; }

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e63a20; }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #e63a20; }

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e63a20; }

.mat-error {
  color: #e63a20; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1982ee; }

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #54d2df; }

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e63a20; }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e63a20; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.mat-icon.mat-primary {
  color: #1982ee; }

.mat-icon.mat-accent {
  color: #54d2df; }

.mat-icon.mat-warn {
  color: #e63a20; }

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38); }

.mat-input-element {
  caret-color: #1982ee; }
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.mat-accent .mat-input-element {
  caret-color: #54d2df; }

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #e63a20; }

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e63a20; }

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.mat-list-item-disabled {
  background-color: #eeeeee; }

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04); }

.mat-menu-panel {
  background: white; }
  .mat-menu-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.mat-menu-item .mat-icon:not([color]),
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.mat-paginator {
  background: white; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-progress-bar-background {
  fill: #039be5; }

.mat-progress-bar-buffer {
  background-color: #039be5; }

.mat-progress-bar-fill::after {
  background-color: #1982ee; }

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e1f5fa; }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e1f5fa; }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #54d2df; }

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #e53935; }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #e53935; }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e63a20; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #1982ee; }

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #54d2df; }

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #e63a20; }

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1982ee; }

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1982ee; }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #54d2df; }

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #54d2df; }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e63a20; }

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e63a20; }

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.mat-radio-button .mat-ripple-element {
  background-color: black; }

.mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.mat-select-panel {
  background: white; }
  .mat-select-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.12); }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1982ee; }

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #54d2df; }

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e63a20; }

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e63a20; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-drawer.mat-drawer-push {
    background-color: white; }
  .mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12); }
  .mat-drawer-side.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none; }

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none; }
  [dir='rtl'] .mat-drawer-side.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #54d2df; }

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(84, 210, 223, 0.54); }

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #54d2df; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1982ee; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(25, 130, 238, 0.54); }

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1982ee; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e63a20; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(230, 58, 32, 0.54); }

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e63a20; }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black; }

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa; }

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #1982ee; }

.mat-primary .mat-slider-thumb-label-text {
  color: white; }

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #54d2df; }

.mat-accent .mat-slider-thumb-label-text {
  color: #54d2df; }

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #e63a20; }

.mat-warn .mat-slider-thumb-label-text {
  color: white; }

.mat-slider-focus-ring {
  background-color: rgba(84, 210, 223, 0.2); }

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54); }

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white; }

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #1982ee;
  color: white; }

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e63a20; }

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e63a20; }

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white; }

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-sort-header-arrow {
  color: #757575; }

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(3, 155, 229, 0.3); }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1982ee; }

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 250, 0.3); }

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #54d2df; }

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: #54d2df; }

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(229, 57, 53, 0.3); }

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e63a20; }

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(3, 155, 229, 0.3); }

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #1982ee; }

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 250, 0.3); }

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #54d2df; }

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: #54d2df; }
  .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(84, 210, 223, 0.4); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: #54d2df; }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(84, 210, 223, 0.4); }

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(84, 210, 223, 0.12); }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(229, 57, 53, 0.3); }

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #e63a20; }

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-primary {
    background: #1982ee;
    color: white; }
  .mat-toolbar.mat-accent {
    background: #54d2df;
    color: #54d2df; }
  .mat-toolbar.mat-warn {
    background: #e63a20;
    color: white; }
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.mat-tree {
  background: white; }

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-simple-snackbar-action {
  color: #54d2df; }

body .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.1); }

body .mat-dialog-content {
  margin: 0; }

body .mat-dialog-container {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  -ms-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22); }

body mat-checkbox.white-checkbox:not(.mat-checkbox-checked) .mat-checkbox-frame {
  background: #FFFFFF;
  border-color: #FFFFFF; }

body .cdk-overlay-container .cdk-overlay-pane.overflow-visible mat-dialog-container {
  overflow: visible;
  padding: 0 24px; }

body .cdk-overlay-container .cdk-overlay-pane.no-padding mat-dialog-container {
  padding: 0; }

body .cdk-overlay-container .cdk-overlay-pane.large-height .mat-dialog-content {
  max-height: 90vh; }

body .cdk-overlay-container .full-width + * > .cdk-overlay-pane {
  width: 100%; }

body .cdk-overlay-container .mat-dialog-container-draggable mat-dialog-container.mat-dialog-container {
  padding: 0;
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.22); }

body .mat-menu-panel.dark-menu-background {
  background-color: #0f3357; }
  body .mat-menu-panel.dark-menu-background .mat-menu-content {
    background-color: #0f3357;
    padding-left: 10px;
    padding-right: 10px; }
  body .mat-menu-panel.dark-menu-background .mat-menu-item {
    color: white;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 14px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    height: 30px;
    line-height: 30px; }
    body .mat-menu-panel.dark-menu-background .mat-menu-item:hover, body .mat-menu-panel.dark-menu-background .mat-menu-item.selected {
      background-color: #5491E8;
      color: white; }

body .mat-menu-panel.blue-menu-background {
  background-color: #5491E8; }
  body .mat-menu-panel.blue-menu-background .mat-menu-content {
    background-color: #5491E8;
    padding-left: 10px;
    padding-right: 10px; }
  body .mat-menu-panel.blue-menu-background .mat-menu-item {
    font-size: 14px;
    line-height: 1;
    color: white;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    height: 25px; }
    body .mat-menu-panel.blue-menu-background .mat-menu-item:hover {
      color: #27273b;
      background: none; }
    body .mat-menu-panel.blue-menu-background .mat-menu-item:not(:last-child) {
      border-bottom: 1px solid #FFFFFF;
      padding-bottom: 5px;
      margin-bottom: 5px; }
    @media (max-width: 768px) {
      body .mat-menu-panel.blue-menu-background .mat-menu-item {
        height: 60px;
        min-width: 60vw;
        font-size: 16px; } }

body .mat-menu-panel.menu-mobile-content {
  min-width: calc(100% - 60px) !important;
  max-width: 100% !important;
  background-color: #0f3357;
  margin: 0 30px; }
  body .mat-menu-panel.menu-mobile-content .mat-menu-item {
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    background-color: #0f3357;
    height: 60px;
    font-size: 22px; }
    body .mat-menu-panel.menu-mobile-content .mat-menu-item.selected {
      color: #54d2df; }
    body .mat-menu-panel.menu-mobile-content .mat-menu-item:hover {
      background-color: #1f5db6; }

body mat-slide-toggle .mat-slide-toggle-bar {
  width: 30px; }

body mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #54d2df !important;
  border: solid 1px #108995; }
  body mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
    transform: translate3d(16px, 0, 0); }
    body mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
      background-color: #ffffff;
      box-shadow: 0 0 2px 1px rgba(29, 70, 128, 0.26); }

body mat-slide-toggle.blue-toggle-bar .mat-slide-toggle-bar {
  background-color: #5491E8;
  border: 1px solid #1C5CB6; }

body mat-slide-toggle .mat-slide-toggle-bar {
  background-color: #e1224f;
  border: solid 1px #9c0226; }

body mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #ffffff;
  box-shadow: 0 0 2px 1px rgba(29, 70, 128, 0.26); }

body mat-slide-toggle .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ffffff;
  box-shadow: 0 0 2px 1px rgba(29, 70, 128, 0.26); }

body mat-slide-toggle .mat-slide-toggle-thumb-container {
  height: 12px;
  width: 12px;
  top: 0; }
  body mat-slide-toggle .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
    height: 12px;
    width: 12px; }

body .red-snackbar {
  background-color: #E63A20; }
  body .red-snackbar .mat-simple-snackbar-action {
    color: white; }
    body .red-snackbar .mat-simple-snackbar-action .mat-button-wrapper {
      font-weight: 600; }

.BLUE1 {
  background-color: #5491E8; }

.BLUE2 {
  background-color: #1C5CB6; }

.BLUE3 {
  background-color: #0f3357; }

.RED1 {
  background-color: #E1224F; }

.RED2 {
  background-color: #E63A20; }

.RED3 {
  background-color: #870b58; }

.GREEN1 {
  background-color: #219EAB; }

.GREEN2 {
  background-color: #126939; }

.PURPLE1 {
  background-color: #52489c; }

.ORANGE1 {
  background-color: #FF5802; }

.ngx-datatable.dln-table {
  background: #FFF;
  /**
       * Shared Styles
       */
  /**
       * Global Row Styles
       */
  /**
       * Header Styles
       * Header Styles
       */
  /**
       * Body Styles
       */
  /**
       * Footer Styles
       */ }
  .ngx-datatable.dln-table.striped .datatable-row-odd {
    background: #eee; }
  .ngx-datatable.dln-table.single-selection .datatable-body-row.active, .ngx-datatable.dln-table.multi-selection .datatable-body-row.active, .ngx-datatable.dln-table.multi-click-selection .datatable-body-row.active {
    background-color: #304FFE;
    color: #FFF; }
    .ngx-datatable.dln-table.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.dln-table.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.dln-table.multi-click-selection .datatable-body-row.active .datatable-row-group {
      background-color: #304FFE;
      color: #FFF; }
  .ngx-datatable.dln-table.single-selection .datatable-body-row.active:hover, .ngx-datatable.dln-table.multi-selection .datatable-body-row.active:hover, .ngx-datatable.dln-table.multi-click-selection .datatable-body-row.active:hover {
    background-color: #193AE4;
    color: #FFF; }
    .ngx-datatable.dln-table.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.dln-table.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.dln-table.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
      background-color: #193AE4;
      color: #FFF; }
  .ngx-datatable.dln-table.single-selection .datatable-body-row.active:focus, .ngx-datatable.dln-table.multi-selection .datatable-body-row.active:focus, .ngx-datatable.dln-table.multi-click-selection .datatable-body-row.active:focus {
    background-color: #2041EF;
    color: #FFF; }
    .ngx-datatable.dln-table.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.dln-table.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.dln-table.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
      background-color: #2041EF;
      color: #FFF; }
  .ngx-datatable.dln-table:not(.cell-selection) .datatable-body-row:hover {
    background-color: transparent;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    transition-property: background;
    transition-duration: .3s;
    transition-timing-function: linear; }
    .ngx-datatable.dln-table:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
      transition-property: background;
      transition-duration: .3s;
      transition-timing-function: linear; }
  .ngx-datatable.dln-table:not(.cell-selection) .datatable-body-row:focus {
    background-color: #ddd; }
    .ngx-datatable.dln-table:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
      background-color: #ddd; }
  .ngx-datatable.dln-table.cell-selection .datatable-body-cell:hover {
    background-color: white;
    transition-property: background;
    transition-duration: .3s;
    transition-timing-function: linear; }
    .ngx-datatable.dln-table.cell-selection .datatable-body-cell:hover .datatable-row-group {
      transition-property: background;
      transition-duration: .3s;
      transition-timing-function: linear; }
  .ngx-datatable.dln-table.cell-selection .datatable-body-cell:focus {
    background-color: #ddd; }
    .ngx-datatable.dln-table.cell-selection .datatable-body-cell:focus .datatable-row-group {
      background-color: #ddd; }
  .ngx-datatable.dln-table.cell-selection .datatable-body-cell.active {
    background-color: #304FFE;
    color: #FFF; }
    .ngx-datatable.dln-table.cell-selection .datatable-body-cell.active .datatable-row-group {
      background-color: #304FFE;
      color: #FFF; }
    .ngx-datatable.dln-table.cell-selection .datatable-body-cell.active:hover {
      background-color: #193AE4;
      color: #FFF; }
      .ngx-datatable.dln-table.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
        background-color: #193AE4;
        color: #FFF; }
    .ngx-datatable.dln-table.cell-selection .datatable-body-cell.active:focus {
      background-color: #2041EF;
      color: #FFF; }
      .ngx-datatable.dln-table.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
        background-color: #2041EF;
        color: #FFF; }
  .ngx-datatable.dln-table .empty-row {
    height: 50px;
    text-align: left;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0; }
  .ngx-datatable.dln-table .loading-row {
    text-align: left;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0; }
  .ngx-datatable.dln-table .datatable-header .datatable-row-left, .ngx-datatable.dln-table .datatable-body .datatable-row-left {
    background-color: #5491E8;
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==); }
    .ngx-datatable.dln-table .datatable-header .datatable-row-left .datatable-body-cell .datatable-body-cell-label, .ngx-datatable.dln-table .datatable-body .datatable-row-left .datatable-body-cell .datatable-body-cell-label {
      color: #FFFFFF; }
  .ngx-datatable.dln-table .datatable-header .datatable-row-left {
    background-color: transparent; }
  .ngx-datatable.dln-table .datatable-header .datatable-row-right, .ngx-datatable.dln-table .datatable-body .datatable-row-right {
    background-position: 0 0;
    background-color: #fff;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==); }
  .ngx-datatable.dln-table.header-full-height .datatable-header .datatable-header-cell {
    height: 100%; }
  .ngx-datatable.dln-table .datatable-header {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #0f3357;
    color: white; }
    .ngx-datatable.dln-table .datatable-header .datatable-header-cell {
      text-align: left;
      padding: 0 10px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      height: 33px;
      display: -webkit-inline-box;
      display: -moz-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell:not(:last-child) {
        border-right: 1px solid #FFFFFF; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
        position: relative; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell.longpress .draggable::after {
        transition: transform 400ms ease, opacity 400ms ease;
        opacity: .5;
        transform: scale(1); }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell .draggable::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        height: 60px;
        width: 60px;
        background: #eee;
        border-radius: 100%;
        opacity: 1;
        filter: none;
        transform: scale(0);
        z-index: 9999;
        pointer-events: none; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell.dragging .resize-handle {
        border-right: none; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell .sort-btn {
        margin-left: 10px; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell.sortable:not(.sort-active):after {
        font-family: 'logbook' !important;
        speak: none;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell.sortable .datatable-header-cell-template-wrap {
        cursor: pointer; }
      .ngx-datatable.dln-table .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
        display: inline-block;
        height: auto; }
    .ngx-datatable.dln-table .datatable-header .resize-handle {
      border-right: solid 1px #eee; }
  .ngx-datatable.dln-table .datatable-body {
    border-right: 1px solid #cbcbd5;
    border-left: 1px solid #cbcbd5;
    overflow: hidden; }
    .ngx-datatable.dln-table .datatable-body .datatable-row-detail {
      background: #f5f5f5;
      padding: 0;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
      overflow-y: auto; }
    .ngx-datatable.dln-table .datatable-body .datatable-group-header {
      background: #f5f5f5;
      border-bottom: solid 1px #D9D8D9;
      border-top: solid 1px #D9D8D9; }
    .ngx-datatable.dln-table .datatable-body .datatable-body-row {
      border-bottom: 1px solid #cbcbd5; }
      .ngx-datatable.dln-table .datatable-body .datatable-body-row.read {
        background-color: #f8f8f9; }
      .ngx-datatable.dln-table .datatable-body .datatable-body-row.unread .datatable-body-cell .datatable-body-cell-label {
        font-weight: bold; }
      .ngx-datatable.dln-table .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.dln-table .datatable-body .datatable-body-row .datatable-body-group-cell {
        text-align: left;
        padding: 0.9rem 10px;
        vertical-align: top;
        border-top: 0;
        color: rgba(0, 0, 0, 0.87);
        transition: width 0.3s ease;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        display: -webkit-inline-box;
        display: -moz-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center; }
        .ngx-datatable.dln-table .datatable-body .datatable-body-row .datatable-body-cell.cell-full-width .datatable-body-cell-label, .ngx-datatable.dln-table .datatable-body .datatable-body-row .datatable-body-group-cell.cell-full-width .datatable-body-cell-label {
          width: 100%; }
    .ngx-datatable.dln-table .datatable-body .progress-linear {
      display: block;
      position: relative;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;
      position: absolute; }
      .ngx-datatable.dln-table .datatable-body .progress-linear .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        -webkit-transform: translate(0, 0) scale(1, 1);
        transform: translate(0, 0) scale(1, 1);
        background-color: #aad1f9; }
        .ngx-datatable.dln-table .datatable-body .progress-linear .container .bar {
          transition: all .2s linear;
          -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          transition: -webkit-transform .2s linear;
          transition: transform .2s linear;
          background-color: #106cc8;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 5px; }
  .ngx-datatable.dln-table .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54); }
    .ngx-datatable.dln-table .datatable-footer .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem; }
    .ngx-datatable.dln-table .datatable-footer .datatable-pager {
      margin: 0 10px; }
      .ngx-datatable.dln-table .datatable-footer .datatable-pager li {
        vertical-align: middle; }
        .ngx-datatable.dln-table .datatable-footer .datatable-pager li.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important; }
        .ngx-datatable.dln-table .datatable-footer .datatable-pager li.active a {
          background-color: rgba(158, 158, 158, 0.2);
          font-weight: bold; }
      .ngx-datatable.dln-table .datatable-footer .datatable-pager a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0 6px;
        border-radius: 3px;
        margin: 6px 3px;
        text-align: center;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
        vertical-align: bottom; }
        .ngx-datatable.dln-table .datatable-footer .datatable-pager a:hover {
          color: rgba(0, 0, 0, 0.75);
          background-color: rgba(158, 158, 158, 0.2); }
      .ngx-datatable.dln-table .datatable-footer .datatable-pager .datatable-icon-left, .ngx-datatable.dln-table .datatable-footer .datatable-pager .datatable-icon-skip, .ngx-datatable.dln-table .datatable-footer .datatable-pager .datatable-icon-right, .ngx-datatable.dln-table .datatable-footer .datatable-pager .datatable-icon-prev {
        font-size: 20px;
        line-height: 20px;
        padding: 0 3px; }

.ngx-datatable.dln-table.otp-table.striped.message-table .datatable-header .datatable-header-cell {
  height: 80px; }

.ngx-datatable.dln-table.otp-table.striped .datatable-body .datatable-body-row .datatable-body-cell {
  padding-right: 0; }
  .ngx-datatable.dln-table.otp-table.striped .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
    width: 100%;
    height: 20px;
    color: #27273b;
    font-size: 12px;
    font-weight: 600;
    border-right: 1px solid #cbcbd5; }

.ngx-datatable.dln-table.otp-table.striped .datatable-row-odd {
  background: #f8f8f9; }

.ngx-datatable.dln-table.otp-table.striped .datatable-header {
  background: none; }
  .ngx-datatable.dln-table.otp-table.striped .datatable-header .datatable-header-cell {
    height: 60px;
    border-right: none;
    padding-right: 0; }
    .ngx-datatable.dln-table.otp-table.striped .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
      width: 100%;
      border-right: solid 1px #FFFFFF; }
    .ngx-datatable.dln-table.otp-table.striped .datatable-header .datatable-header-cell .resize-handle {
      border-right: none; }
    .ngx-datatable.dln-table.otp-table.striped .datatable-header .datatable-header-cell.sortable:not(.sort-active) .datatable-header-cell-template-wrap span {
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-flex;
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-direction: normal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
      .ngx-datatable.dln-table.otp-table.striped .datatable-header .datatable-header-cell.sortable:not(.sort-active) .datatable-header-cell-template-wrap span:first-child:after {
        font-family: 'logbook' !important;
        speak: none;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
    .ngx-datatable.dln-table.otp-table.striped .datatable-header .datatable-header-cell.sortable:not(.sort-active):after {
      display: none; }

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0; }
  .datatable-checkbox input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none; }
    .datatable-checkbox input[type='checkbox']:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: "";
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #f2f2f2; }
    .datatable-checkbox input[type='checkbox']:checked:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: .5rem;
      border-color: #009688;
      border-top-style: none;
      border-right-style: none; }
    .datatable-checkbox input[type='checkbox']:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer; }

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }

/* ==========================================================================
Reset========================================================================== */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif; }
  *:focus {
    outline: none; }

html, body, h1, h2, h3, h4, h5, h6,
p, a, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  font-size: 16px;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  color: #27273b; }

body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  overflow: hidden; }
  @media print {
    body {
      overflow: auto; } }

dln-main {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100vw;
  height: 100vh; }
  @media print {
    dln-main {
      height: 100%;
      display: block !important; } }
  dln-main img.loading-logo {
    display: block !important;
    width: 350px;
    height: 350px; }
    @media (max-width: 768px) {
      dln-main img.loading-logo {
        width: 175px;
        height: 175px; } }
  dln-main .dln-fading-circle {
    width: 500px;
    height: 500px;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 250px); }
    @media (max-width: 768px) {
      dln-main .dln-fading-circle {
        width: 250px;
        height: 250px;
        top: calc(50% - 125px);
        left: calc(50% - 125px); } }
    dln-main .dln-fading-circle .dln-circle {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
      dln-main .dln-fading-circle .dln-circle:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15px;
        height: 15px;
        background-color: #4f5d75;
        border-radius: 100%;
        -webkit-animation: dln-circleFadeDelay 1.2s infinite ease-in-out both;
        animation: dln-circleFadeDelay 1.2s infinite ease-in-out both; }
    dln-main .dln-fading-circle .dln-circle2 {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg); }
    dln-main .dln-fading-circle .dln-circle3 {
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg); }
    dln-main .dln-fading-circle .dln-circle4 {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    dln-main .dln-fading-circle .dln-circle5 {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg); }
    dln-main .dln-fading-circle .dln-circle6 {
      -webkit-transform: rotate(75deg);
      -ms-transform: rotate(75deg);
      transform: rotate(75deg); }
    dln-main .dln-fading-circle .dln-circle7 {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
    dln-main .dln-fading-circle .dln-circle8 {
      -webkit-transform: rotate(105deg);
      -ms-transform: rotate(105deg);
      transform: rotate(105deg); }
    dln-main .dln-fading-circle .dln-circle9 {
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg); }
    dln-main .dln-fading-circle .dln-circle10 {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }
    dln-main .dln-fading-circle .dln-circle11 {
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg); }
    dln-main .dln-fading-circle .dln-circle12 {
      -webkit-transform: rotate(165deg);
      -ms-transform: rotate(165deg);
      transform: rotate(165deg); }
    dln-main .dln-fading-circle .dln-circle13 {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
    dln-main .dln-fading-circle .dln-circle14 {
      -webkit-transform: rotate(195deg);
      -ms-transform: rotate(195deg);
      transform: rotate(195deg); }
    dln-main .dln-fading-circle .dln-circle15 {
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg); }
    dln-main .dln-fading-circle .dln-circle16 {
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg); }
    dln-main .dln-fading-circle .dln-circle17 {
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg); }
    dln-main .dln-fading-circle .dln-circle18 {
      -webkit-transform: rotate(255deg);
      -ms-transform: rotate(255deg);
      transform: rotate(255deg); }
    dln-main .dln-fading-circle .dln-circle19 {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg); }
    dln-main .dln-fading-circle .dln-circle20 {
      -webkit-transform: rotate(285deg);
      -ms-transform: rotate(285deg);
      transform: rotate(285deg); }
    dln-main .dln-fading-circle .dln-circle21 {
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg); }
    dln-main .dln-fading-circle .dln-circle22 {
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: rotate(315deg); }
    dln-main .dln-fading-circle .dln-circle23 {
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg); }
    dln-main .dln-fading-circle .dln-circle24 {
      -webkit-transform: rotate(345deg);
      -ms-transform: rotate(345deg);
      transform: rotate(345deg); }
    dln-main .dln-fading-circle .dln-circle2:before {
      -webkit-animation-delay: -2.2s;
      animation-delay: -2.2s; }
    dln-main .dln-fading-circle .dln-circle3:before {
      -webkit-animation-delay: -2.1s;
      animation-delay: -2.1s; }
    dln-main .dln-fading-circle .dln-circle4:before {
      -webkit-animation-delay: -2.0s;
      animation-delay: -2.0s; }
    dln-main .dln-fading-circle .dln-circle5:before {
      -webkit-animation-delay: -1.9s;
      animation-delay: -1.9s; }
    dln-main .dln-fading-circle .dln-circle6:before {
      -webkit-animation-delay: -1.8s;
      animation-delay: -1.8s; }
    dln-main .dln-fading-circle .dln-circle7:before {
      -webkit-animation-delay: -1.7s;
      animation-delay: -1.7s; }
    dln-main .dln-fading-circle .dln-circle8:before {
      -webkit-animation-delay: -1.6s;
      animation-delay: -1.6s; }
    dln-main .dln-fading-circle .dln-circle9:before {
      -webkit-animation-delay: -1.5s;
      animation-delay: -1.5s; }
    dln-main .dln-fading-circle .dln-circle10:before {
      -webkit-animation-delay: -1.4s;
      animation-delay: -1.4s; }
    dln-main .dln-fading-circle .dln-circle11:before {
      -webkit-animation-delay: -1.3s;
      animation-delay: -1.3s; }
    dln-main .dln-fading-circle .dln-circle12:before {
      -webkit-animation-delay: -1.2s;
      animation-delay: -1.2s; }
    dln-main .dln-fading-circle .dln-circle13:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s; }
    dln-main .dln-fading-circle .dln-circle14:before {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s; }
    dln-main .dln-fading-circle .dln-circle15:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s; }
    dln-main .dln-fading-circle .dln-circle16:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s; }
    dln-main .dln-fading-circle .dln-circle17:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s; }
    dln-main .dln-fading-circle .dln-circle18:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s; }
    dln-main .dln-fading-circle .dln-circle19:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s; }
    dln-main .dln-fading-circle .dln-circle20:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s; }
    dln-main .dln-fading-circle .dln-circle21:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s; }
    dln-main .dln-fading-circle .dln-circle22:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s; }
    dln-main .dln-fading-circle .dln-circle23:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s; }
    dln-main .dln-fading-circle .dln-circle24:before {
      -webkit-animation-delay: 0s;
      animation-delay: 0s; }

@-webkit-keyframes dln-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes dln-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.side-view dln-details-instruction {
  position: absolute;
  z-index: 101;
  top: -40px;
  right: 0;
  width: 100%;
  bottom: 0;
  overflow: auto; }
  @media (max-width: 768px) {
    .side-view dln-details-instruction {
      z-index: 400; } }
  @media print {
    .side-view dln-details-instruction {
      top: 0;
      height: 100%; } }

/* ==========================================================================
Generic styles
========================================================================== */
[data-hidden="true"] {
  display: none !important; }

/* Tooltip */
.ng-tooltip {
  max-width: 200px;
  background-color: #5491E8;
  color: #fff;
  text-align: center;
  font-size: 0.8em;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  z-index: 1000;
  display: block;
  opacity: 0;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms; }

.ng-tooltip-show {
  opacity: 1; }

.ng-tooltip::after {
  content: "";
  position: absolute;
  border-style: solid; }

.ng-tooltip-top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #5491E8 transparent transparent transparent; }

.ng-tooltip-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent #5491E8 transparent; }

.ng-tooltip-left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent #5491E8; }

.ng-tooltip-right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent #5491E8 transparent transparent; }

.tooltip-menu.ng-tooltip {
  font-size: 14px;
  background-color: #4E667F; }

.tooltip-menu.ng-tooltip-show {
  opacity: 1; }

.tooltip-menu.ng-tooltip-top::after {
  border-color: #4E667F transparent transparent transparent; }

.tooltip-menu.ng-tooltip-bottom::after {
  border-color: transparent transparent #4E667F transparent; }

.tooltip-menu.ng-tooltip-left::after {
  border-color: transparent transparent transparent #4E667F; }

.tooltip-menu.ng-tooltip-right::after {
  border-color: transparent #4E667F transparent transparent; }

@media (max-width: 768px) {
  .no-mobile {
    display: none; } }

@media (min-width: 768px) {
  .only-mobile {
    display: none; } }

@media (max-width: 768px) {
  .ng-tooltip-show {
    display: none; } }

/*
 global animations
 */
.shake {
  -webkit-animation: shake-input 0.3s 1;
  -moz-animation: shake-input 0.3s 1;
  -o-animation: shake-input 0.3s 1;
  animation: shake-input 0.3s 1; }

@-webkit-keyframes shake-input {
  10%, 90% {
    -moz-transform: translate3d(-1px, 0, 0);
    -o-transform: translate3d(-1px, 0, 0);
    -ms-transform: translate3d(-1px, 0, 0);
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -moz-transform: translate3d(-2px, 0, 0);
    -o-transform: translate3d(-2px, 0, 0);
    -ms-transform: translate3d(-2px, 0, 0);
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); } }

@-moz-keyframes shake-input {
  10%, 90% {
    -moz-transform: translate3d(-1px, 0, 0);
    -o-transform: translate3d(-1px, 0, 0);
    -ms-transform: translate3d(-1px, 0, 0);
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -moz-transform: translate3d(-2px, 0, 0);
    -o-transform: translate3d(-2px, 0, 0);
    -ms-transform: translate3d(-2px, 0, 0);
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); } }

@-o-keyframes shake-input {
  10%, 90% {
    -moz-transform: translate3d(-1px, 0, 0);
    -o-transform: translate3d(-1px, 0, 0);
    -ms-transform: translate3d(-1px, 0, 0);
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -moz-transform: translate3d(-2px, 0, 0);
    -o-transform: translate3d(-2px, 0, 0);
    -ms-transform: translate3d(-2px, 0, 0);
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); } }

@keyframes shake-input {
  10%, 90% {
    -moz-transform: translate3d(-1px, 0, 0);
    -o-transform: translate3d(-1px, 0, 0);
    -ms-transform: translate3d(-1px, 0, 0);
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -moz-transform: translate3d(-2px, 0, 0);
    -o-transform: translate3d(-2px, 0, 0);
    -ms-transform: translate3d(-2px, 0, 0);
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0); } }

.tag-color-select, .color-select {
  min-width: 60px !important; }
  .tag-color-select mat-option .mat-option-text span, .color-select mat-option .mat-option-text span {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px; }
    .tag-color-select mat-option .mat-option-text span.red, .color-select mat-option .mat-option-text span.red {
      background-color: #E63A20; }
    .tag-color-select mat-option .mat-option-text span.blue, .color-select mat-option .mat-option-text span.blue {
      background-color: #1982ee; }
    .tag-color-select mat-option .mat-option-text span.green, .color-select mat-option .mat-option-text span.green {
      background-color: #54d2df; }
    .tag-color-select mat-option .mat-option-text span.purple, .color-select mat-option .mat-option-text span.purple {
      background-color: #52489c; }
    .tag-color-select mat-option .mat-option-text span.dark, .color-select mat-option .mat-option-text span.dark {
      background-color: #27273b; }
    .tag-color-select mat-option .mat-option-text span.gray-dark, .color-select mat-option .mat-option-text span.gray-dark {
      background-color: #4f5d75; }
    .tag-color-select mat-option .mat-option-text span.gray, .color-select mat-option .mat-option-text span.gray {
      background-color: #848499; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
