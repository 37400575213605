/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
    url('/content/fonts/opensans/open-sans-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('/content/fonts/opensans/open-sans-v14-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/content/fonts/opensans/open-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('/content/fonts/opensans/open-sans-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('/content/fonts/opensans/open-sans-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('/content/fonts/opensans/open-sans-v14-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('/content/fonts/opensans/open-sans-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('/content/fonts/opensans/open-sans-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('/content/fonts/opensans/open-sans-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-800.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-800italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: fallback;
    src: url('/content/fonts/opensans/open-sans-v14-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
    url('/content/fonts/opensans/open-sans-v14-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/opensans/open-sans-v14-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/opensans/open-sans-v14-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/opensans/open-sans-v14-latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* icomoon */

@font-face {
    font-family: 'linearicons';
    src: url('/content/fonts/linearicons/linearicons.eot?5c471g');
    src: url('/content/fonts/linearicons/linearicons.eot?5c471g#iefix') format('embedded-opentype'),
    url('/content/fonts/linearicons/linearicons.svg?5c471g#linearicons') format('svg'),
    url('/content/fonts/linearicons/linearicons.ttf?5c471g') format('truetype'),
    url('/content/fonts/linearicons/linearicons.woff?5c471g') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'logbook';
    src:
        url('/content/fonts/logbook/logbook.woff2?e8ycpn') format('woff2'),
        url('/content/fonts/logbook/logbook.ttf?e8ycpn') format('truetype'),
        url('/content/fonts/logbook/logbook.woff?e8ycpn') format('woff'),
        url('/content/fonts/logbook/logbook.svg?e8ycpn#logbook') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/* material design */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('/content/fonts/material/material-font.woff2') format('woff2');
}
