@import "../colors";
@import "../colors-otp";
@import "../variables";
@import "../mixin";

.ngx-datatable.dln-table {

    $cell-left-padding: 10px;

    background: #FFF;
    /**
       * Shared Styles
       */
    /**
       * Global Row Styles
       */
    /**
       * Header Styles
       * Header Styles
       */
    /**
       * Body Styles
       */
    /**
       * Footer Styles
       */
    &.striped .datatable-row-odd {
        background: #eee;
    }
    &.single-selection .datatable-body-row.active, &.multi-selection .datatable-body-row.active, &.multi-click-selection .datatable-body-row.active {
        background-color: #304FFE;
        color: #FFF;
        .datatable-row-group {
            background-color: #304FFE;
            color: #FFF;
        }
    }
    &.single-selection .datatable-body-row.active:hover, &.multi-selection .datatable-body-row.active:hover, &.multi-click-selection .datatable-body-row.active:hover {
        background-color: #193AE4;
        color: #FFF;
        .datatable-row-group {
            background-color: #193AE4;
            color: #FFF;
        }
    }
    &.single-selection .datatable-body-row.active:focus, &.multi-selection .datatable-body-row.active:focus, &.multi-click-selection .datatable-body-row.active:focus {
        background-color: #2041EF;
        color: #FFF;
        .datatable-row-group {
            background-color: #2041EF;
            color: #FFF;
        }
    }
    &:not(.cell-selection) .datatable-body-row {
        &:hover {
            background-color: transparent;
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
            transition-property: background;
            transition-duration: .3s;
            transition-timing-function: linear;
            .datatable-row-group {
                transition-property: background;
                transition-duration: .3s;
                transition-timing-function: linear;
            }
        }
        &:focus {
            background-color: #ddd;
            .datatable-row-group {
                background-color: #ddd;
            }
        }
    }
    &.cell-selection .datatable-body-cell {
        &:hover {
            background-color: white;
            transition-property: background;
            transition-duration: .3s;
            transition-timing-function: linear;
            .datatable-row-group {
                transition-property: background;
                transition-duration: .3s;
                transition-timing-function: linear;
            }
        }
        &:focus {
            background-color: #ddd;
            .datatable-row-group {
                background-color: #ddd;
            }
        }
        &.active {
            background-color: #304FFE;
            color: #FFF;
            .datatable-row-group {
                background-color: #304FFE;
                color: #FFF;
            }
            &:hover {
                background-color: #193AE4;
                color: #FFF;
                .datatable-row-group {
                    background-color: #193AE4;
                    color: #FFF;
                }
            }
            &:focus {
                background-color: #2041EF;
                color: #FFF;
                .datatable-row-group {
                    background-color: #2041EF;
                    color: #FFF;
                }
            }
        }
    }
    .empty-row {
        height: 50px;
        text-align: left;
        padding: .5rem 1.2rem;
        vertical-align: top;
        border-top: 0;
    }
    .loading-row {
        text-align: left;
        padding: .5rem 1.2rem;
        vertical-align: top;
        border-top: 0;
    }
    .datatable-header .datatable-row-left, .datatable-body .datatable-row-left {
        background-color: $BlueLight;
        background-position: 100% 0;
        background-repeat: repeat-y;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);

        .datatable-body-cell .datatable-body-cell-label{
            color: $White;
        }
    }

    .datatable-header .datatable-row-left {
        background-color: transparent;
    }

    .datatable-header .datatable-row-right, .datatable-body .datatable-row-right {
        background-position: 0 0;
        background-color: #fff;
        background-repeat: repeat-y;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
    }

    &.header-full-height {
        .datatable-header .datatable-header-cell {
            height: 100%;
        }
    }

    .datatable-header {
        @include flex-box;
        @include align-items(center);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        background-color: $BlueDarker;
        color: white;

        .datatable-header-cell {
            text-align: left;
            padding: 0 $cell-left-padding;
            color: white;
            font-size: 14px;
            font-weight: 500;
            height: 33px;
            @include inline-flex();
            @include align-items(center);

            &:not(:last-child) {
                border-right: 1px solid $White;
            }

            .datatable-header-cell-wrapper {
                position: relative;
            }

            &.longpress .draggable::after {
                transition: transform 400ms ease, opacity 400ms ease;
                opacity: .5;
                transform: scale(1);
            }
            .draggable::after {
                content: " ";
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -30px 0 0 -30px;
                height: 60px;
                width: 60px;
                background: #eee;
                border-radius: 100%;
                opacity: 1;
                filter: none;
                transform: scale(0);
                z-index: 9999;
                pointer-events: none;
            }
            &.dragging .resize-handle {
                border-right: none;
            }

            .sort-btn {
                margin-left: 10px;
            }

            &.sortable:not(.sort-active) {
                &:after {
                    font-family: 'logbook' !important;
                    speak: none;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;

                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: unicode($dln-icon-chevron_double);
                }
            }

            &.sortable {
                .datatable-header-cell-template-wrap {
                    cursor: pointer;
                }
            }

           & .datatable-header-cell-template-wrap {
             display: inline-block;
             height: auto;
           }
        }
        .resize-handle {
            border-right: solid 1px #eee;
        }
    }
    .datatable-body {
        border-right: 1px solid $BorderColor;
        border-left: 1px solid $BorderColor;
        overflow: hidden;

        .datatable-row-detail {
            background: #f5f5f5;
            padding: 0;
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
            overflow-y: auto;
        }
        .datatable-group-header {
            background: #f5f5f5;
            border-bottom: solid 1px #D9D8D9;
            border-top: solid 1px #D9D8D9;
        }
        .datatable-body-row {
            border-bottom: 1px solid $BorderColor;
            &.read {
                background-color: $GrayLight;
            }
            &.unread {
                .datatable-body-cell {
                    .datatable-body-cell-label {
                        font-weight: bold;
                    }
                }
            }
            .datatable-body-cell, .datatable-body-group-cell {
                text-align: left;
                padding: .9rem $cell-left-padding;
                vertical-align: top;
                border-top: 0;
                color: rgba(0, 0, 0, 0.87);
                transition: width 0.3s ease;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
                @include inline-flex();
                @include align-items(center);

                &.cell-full-width {
                  .datatable-body-cell-label {
                    width: 100%;
                  }
                }
            }
        }
        .progress-linear {
            display: block;
            position: relative;
            width: 100%;
            height: 5px;
            padding: 0;
            margin: 0;
            position: absolute;
            .container {
                display: block;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 5px;
                -webkit-transform: translate(0, 0) scale(1, 1);
                transform: translate(0, 0) scale(1, 1);
                background-color: #aad1f9;
                .bar {
                    transition: all .2s linear;
                    -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
                    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
                    transition: -webkit-transform .2s linear;
                    transition: transform .2s linear;
                    background-color: #106cc8;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 5px;
                }
            }
        }
    }
    .datatable-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
        .page-count {
            line-height: 50px;
            height: 50px;
            padding: 0 1.2rem;
        }
        .datatable-pager {
            margin: 0 10px;
            li {
                vertical-align: middle;
                &.disabled a {
                    color: rgba(0, 0, 0, 0.26) !important;
                    background-color: transparent !important;
                }
                &.active a {
                    background-color: rgba(158, 158, 158, 0.2);
                    font-weight: bold;
                }
            }
            a {
                height: 22px;
                min-width: 24px;
                line-height: 22px;
                padding: 0 6px;
                border-radius: 3px;
                margin: 6px 3px;
                text-align: center;
                vertical-align: top;
                color: rgba(0, 0, 0, 0.54);
                text-decoration: none;
                vertical-align: bottom;
                &:hover {
                    color: rgba(0, 0, 0, 0.75);
                    background-color: rgba(158, 158, 158, 0.2);
                }
            }
            .datatable-icon-left, .datatable-icon-skip, .datatable-icon-right, .datatable-icon-prev {
                font-size: 20px;
                line-height: 20px;
                padding: 0 3px;
            }
        }
    }
}

.ngx-datatable.dln-table.otp-table.striped {

    &.message-table {
        .datatable-header {
            .datatable-header-cell {
                height: 80px;
            }

        }
    }
    .datatable-body .datatable-body-row .datatable-body-cell {
        padding-right: 0;

        .datatable-body-cell-label {
            width: 100%;
            height: 20px;
            color: $Black;
            font-size: $FontSmallSize;
            font-weight: $FontBold;
            border-right: 1px solid $BorderColor;
        }
    }

    .datatable-row-odd {
        background: $GrayLight;
    }


    .datatable-header {
        background: none;

        .datatable-header-cell {
            height: 60px;
            border-right: none;
            padding-right: 0;


            .datatable-header-cell-template-wrap {
                width: 100%;
                border-right: solid 1px $White;
            }

            .resize-handle {
                border-right: none;
            }

            &.sortable:not(.sort-active) {

                .datatable-header-cell-template-wrap {
                    span {
                        @include flex-all(row, center, flex-start);

                        &:first-child {

                            &:after {
                                font-family: 'logbook' !important;
                                speak: none;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                line-height: 1;

                                /* Better Font Rendering =========== */
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                content: unicode($dln-icon-chevron_double);
                            }
                        }
                    }
                }

                &:after {
                    display: none;
                }
            }
        }
    }
}

/**
 * Checkboxes
**/

.datatable-checkbox {
    position: relative;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    padding: 0;
    input[type='checkbox'] {
        position: relative;
        margin: 0 1rem 0 0;
        cursor: pointer;
        outline: none;
        &:before {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            content: "";
            position: absolute;
            left: 0;
            z-index: 1;
            width: 1rem;
            height: 1rem;
            border: 2px solid #f2f2f2;
        }
        &:checked:before {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            height: .5rem;
            border-color: #009688;
            border-top-style: none;
            border-right-style: none;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1rem;
            height: 1rem;
            background: #fff;
            cursor: pointer;
        }
    }
}

/**
 * Progress bar animations
 */
@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}




