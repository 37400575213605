@import "linearicons-variables";

// https://linearicons.com/free

span[class^="icon-"], span[class*=" icon-"], i[class^="icon-"], i[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'linearicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-calendar-full {
    &:before {
        content: $icon-calendar-full;
    }
}

.icon-keyboard {
    &:before {
        content: $icon-keyboard;
    }
}

.icon-star-empty {
    &:before {
        content: $icon-star-empty;
    }
}

.icon-earth {
    &:before {
        content: $icon-earth;
    }
}

.icon-cog {
    &:before {
        content: $icon-cog;
    }
}

.icon-magic-wand {
    &:before {
        content: $icon-magic-wand;
    }
}

.icon-hourglass {
    &:before {
        content: $icon-hourglass;
    }
}

.icon-clock {
    &:before {
        content: $icon-clock;
    }
}

.icon-rocket {
    &:before {
        content: $icon-rocket;
    }
}

.icon-phone {
    &:before {
        content: $icon-phone;
    }
}

.icon-heart-pulse {
    &:before {
        content: $icon-heart-pulse;
    }
}

.icon-poop {
    &:before {
        content: $icon-poop;
    }
}

.icon-linearicons {
    &:before {
        content: $icon-linearicons;
    }
}

.icon-wheelchair {
    &:before {
        content: $icon-wheelchair;
    }
}

.icon-mustache {
    &:before {
        content: $icon-mustache;
    }
}

.icon-star-half {
    &:before {
        content: $icon-star-half;
    }
}

.icon-license {
    &:before {
        content: $icon-license;
    }
}

.icon-bullhorn {
    &:before {
        content: $icon-bullhorn;
    }
}

.icon-dinner {
    &:before {
        content: $icon-dinner;
    }
}

.icon-unlink {
    &:before {
        content: $icon-unlink;
    }
}

.icon-store {
    &:before {
        content: $icon-store;
    }
}

.icon-thumbs-up {
    &:before {
        content: $icon-thumbs-up;
    }
}

.icon-thumbs-down {
    &:before {
        content: $icon-thumbs-down;
    }
}

.icon-car {
    &:before {
        content: $icon-car;
    }
}

.icon-cloud-sync {
    &:before {
        content: $icon-cloud-sync;
    }
}

.icon-apartment {
    &:before {
        content: $icon-apartment;
    }
}

.icon-sun {
    &:before {
        content: $icon-sun;
    }
}

.icon-bus {
    &:before {
        content: $icon-bus;
    }
}

.icon-dice {
    &:before {
        content: $icon-dice;
    }
}

.icon-database {
    &:before {
        content: $icon-database;
    }
}

.icon-train {
    &:before {
        content: $icon-train;
    }
}

.icon-paw {
    &:before {
        content: $icon-paw;
    }
}

.icon-page-break {
    &:before {
        content: $icon-page-break;
    }
}

.icon-bug {
    &:before {
        content: $icon-bug;
    }
}

.icon-volume-high {
    &:before {
        content: $icon-volume-high;
    }
}

.icon-phone-handset {
    &:before {
        content: $icon-phone-handset;
    }
}

.icon-pointer-left {
    &:before {
        content: $icon-pointer-left;
    }
}

.icon-pointer-right {
    &:before {
        content: $icon-pointer-right;
    }
}

.icon-hand {
    &:before {
        content: $icon-hand;
    }
}

.icon-pointer-up {
    &:before {
        content: $icon-pointer-up;
    }
}

.icon-pointer-down {
    &:before {
        content: $icon-pointer-down;
    }
}

.icon-spell-check {
    &:before {
        content: $icon-spell-check;
    }
}

.icon-printer {
    &:before {
        content: $icon-printer;
    }
}

.icon-users {
    &:before {
        content: $icon-users;
    }
}

.icon-cloud-download {
    &:before {
        content: $icon-cloud-download;
    }
}

.icon-cloud-upload {
    &:before {
        content: $icon-cloud-upload;
    }
}

.icon-pushpin {
    &:before {
        content: $icon-pushpin;
    }
}

.icon-lighter {
    &:before {
        content: $icon-lighter;
    }
}

.icon-graduation-hat {
    &:before {
        content: $icon-graduation-hat;
    }
}

.icon-sad {
    &:before {
        content: $icon-sad;
    }
}

.icon-bicycle {
    &:before {
        content: $icon-bicycle;
    }
}

.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}

.icon-laptop-phone {
    &:before {
        content: $icon-laptop-phone;
    }
}

.icon-volume-medium {
    &:before {
        content: $icon-volume-medium;
    }
}

.icon-eye {
    &:before {
        content: $icon-eye;
    }
}

.icon-funnel {
    &:before {
        content: $icon-funnel;
    }
}

.icon-list {
    &:before {
        content: $icon-list;
    }
}

.icon-line-spacing {
    &:before {
        content: $icon-line-spacing;
    }
}

.icon-exit {
    &:before {
        content: $icon-exit;
    }
}

.icon-coffee-cup {
    &:before {
        content: $icon-coffee-cup;
    }
}

.icon-enter {
    &:before {
        content: $icon-enter;
    }
}

.icon-sort-amount-asc {
    &:before {
        content: $icon-sort-amount-asc;
    }
}

.icon-trash {
    &:before {
        content: $icon-trash;
    }
}

.icon-cloud-check {
    &:before {
        content: $icon-cloud-check;
    }
}

.icon-smile {
    &:before {
        content: $icon-smile;
    }
}

.icon-sync {
    &:before {
        content: $icon-sync;
    }
}

.icon-indent-decrease {
    &:before {
        content: $icon-indent-decrease;
    }
}

.icon-indent-increase {
    &:before {
        content: $icon-indent-increase;
    }
}

.icon-flag {
    &:before {
        content: $icon-flag;
    }
}

.icon-sort-alpha-asc {
    &:before {
        content: $icon-sort-alpha-asc;
    }
}

.icon-cart {
    &:before {
        content: $icon-cart;
    }
}

.icon-move {
    &:before {
        content: $icon-move;
    }
}

.icon-select {
    &:before {
        content: $icon-select;
    }
}

.icon-music-note {
    &:before {
        content: $icon-music-note;
    }
}

.icon-text-size {
    &:before {
        content: $icon-text-size;
    }
}

.icon-alarm {
    &:before {
        content: $icon-alarm;
    }
}

.icon-neutral {
    &:before {
        content: $icon-neutral;
    }
}

.icon-gift {
    &:before {
        content: $icon-gift;
    }
}

.icon-history {
    &:before {
        content: $icon-history;
    }
}

.icon-layers {
    &:before {
        content: $icon-layers;
    }
}

.icon-bubble {
    &:before {
        content: $icon-bubble;
    }
}

.icon-text-format-remove {
    &:before {
        content: $icon-text-format-remove;
    }
}

.icon-star {
    &:before {
        content: $icon-star;
    }
}

.icon-heart {
    &:before {
        content: $icon-heart;
    }
}

.icon-camera {
    &:before {
        content: $icon-camera;
    }
}

.icon-cross-circle {
    &:before {
        content: $icon-cross-circle;
    }
}

.icon-link {
    &:before {
        content: $icon-link;
    }
}

.icon-question-circle {
    &:before {
        content: $icon-question-circle;
    }
}

.icon-picture {
    &:before {
        content: $icon-picture;
    }
}

.icon-shirt {
    &:before {
        content: $icon-shirt;
    }
}

.icon-chart-bars {
    &:before {
        content: $icon-chart-bars;
    }
}

.icon-bold {
    &:before {
        content: $icon-bold;
    }
}

.icon-tag {
    &:before {
        content: $icon-tag;
    }
}

.icon-frame-contract {
    &:before {
        content: $icon-frame-contract;
    }
}

.icon-frame-expand {
    &:before {
        content: $icon-frame-expand;
    }
}

.icon-inbox {
    &:before {
        content: $icon-inbox;
    }
}

.icon-cloud {
    &:before {
        content: $icon-cloud;
    }
}

.icon-menu-circle {
    &:before {
        content: $icon-menu-circle;
    }
}

.icon-volume-low {
    &:before {
        content: $icon-volume-low;
    }
}

.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}

.icon-moon {
    &:before {
        content: $icon-moon;
    }
}

.icon-code {
    &:before {
        content: $icon-code;
    }
}

.icon-camera-video {
    &:before {
        content: $icon-camera-video;
    }
}

.icon-highlight {
    &:before {
        content: $icon-highlight;
    }
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}

.icon-home {
    &:before {
        content: $icon-home;
    }
}

.icon-map-marker {
    &:before {
        content: $icon-map-marker;
    }
}

.icon-text-align-center {
    &:before {
        content: $icon-text-align-center;
    }
}

.icon-text-align-justify {
    &:before {
        content: $icon-text-align-justify;
    }
}

.icon-text-align-left {
    &:before {
        content: $icon-text-align-left;
    }
}

.icon-text-align-right {
    &:before {
        content: $icon-text-align-right;
    }
}

.icon-enter-down {
    &:before {
        content: $icon-enter-down;
    }
}

.icon-exit-up {
    &:before {
        content: $icon-exit-up;
    }
}

.icon-diamond {
    &:before {
        content: $icon-diamond;
    }
}

.icon-file-add {
    &:before {
        content: $icon-file-add;
    }
}

.icon-construction {
    &:before {
        content: $icon-construction;
    }
}

.icon-arrow-right-circle {
    &:before {
        content: $icon-arrow-right-circle;
    }
}

.icon-arrow-down-circle {
    &:before {
        content: $icon-arrow-down-circle;
    }
}

.icon-arrow-left-circle {
    &:before {
        content: $icon-arrow-left-circle;
    }
}

.icon-arrow-up-circle {
    &:before {
        content: $icon-arrow-up-circle;
    }
}

.icon-power-switch {
    &:before {
        content: $icon-power-switch;
    }
}

.icon-crop {
    &:before {
        content: $icon-crop;
    }
}

.icon-pencil {
    &:before {
        content: $icon-pencil;
    }
}

.icon-film-play {
    &:before {
        content: $icon-film-play;
    }
}

.icon-pie-chart {
    &:before {
        content: $icon-pie-chart;
    }
}

.icon-checkmark-circle {
    &:before {
        content: $icon-checkmark-circle;
    }
}

.icon-chevron-right-circle {
    &:before {
        content: $icon-chevron-right-circle;
    }
}

.icon-tablet {
    &:before {
        content: $icon-tablet;
    }
}

.icon-direction-ltr {
    &:before {
        content: $icon-direction-ltr;
    }
}

.icon-chevron-down-circle {
    &:before {
        content: $icon-chevron-down-circle;
    }
}

.icon-chevron-left-circle {
    &:before {
        content: $icon-chevron-left-circle;
    }
}

.icon-chevron-up-circle {
    &:before {
        content: $icon-chevron-up-circle;
    }
}

.icon-plus-circle {
    &:before {
        content: $icon-plus-circle;
    }
}

.icon-direction-rtl {
    &:before {
        content: $icon-direction-rtl;
    }
}

.icon-smartphone {
    &:before {
        content: $icon-smartphone;
    }
}

.icon-book {
    &:before {
        content: $icon-book;
    }
}

.icon-mic {
    &:before {
        content: $icon-mic;
    }
}

.icon-text-format {
    &:before {
        content: $icon-text-format;
    }
}

.icon-download {
    &:before {
        content: $icon-download;
    }
}

.icon-drop {
    &:before {
        content: $icon-drop;
    }
}

.icon-upload {
    &:before {
        content: $icon-upload;
    }
}

.icon-warning {
    &:before {
        content: $icon-warning;
    }
}

.icon-map {
    &:before {
        content: $icon-map;
    }
}

.icon-redo {
    &:before {
        content: $icon-redo;
    }
}

.icon-undo {
    &:before {
        content: $icon-undo;
    }
}

.icon-laptop {
    &:before {
        content: $icon-laptop;
    }
}

.icon-strikethrough {
    &:before {
        content: $icon-strikethrough;
    }
}

.icon-circle-minus {
    &:before {
        content: $icon-circle-minus;
    }
}

.icon-magnifier {
    &:before {
        content: $icon-magnifier;
    }
}

.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}

.icon-cross {
    &:before {
        content: $icon-cross;
    }
}

.icon-volume {
    &:before {
        content: $icon-volume;
    }
}

.icon-file-empty {
    &:before {
        content: $icon-file-empty;
    }
}

.icon-paperclip {
    &:before {
        content: $icon-paperclip;
    }
}

.icon-menu {
    &:before {
        content: $icon-menu;
    }
}

.icon-lock {
    &:before {
        content: $icon-lock;
    }
}

.icon-bookmark {
    &:before {
        content: $icon-bookmark;
    }
}

.icon-screen {
    &:before {
        content: $icon-screen;
    }
}

.icon-location {
    &:before {
        content: $icon-location;
    }
}

.icon-underline {
    &:before {
        content: $icon-underline;
    }
}

.icon-italic {
    &:before {
        content: $icon-italic;
    }
}

.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}

.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}

.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}

.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}

.icon-pilcrow {
    &:before {
        content: $icon-pilcrow;
    }
}

.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}

.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}

.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}

.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
