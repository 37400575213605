@import "variables";
@import "colors";
@import "vendor/logbook/logbook-variables";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-top-left-radius: $radius;
    -ms-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-top-right-radius: $radius;
    -ms-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin blur($blur) {
    -webkit-filter: blur($blur);
    -moz-filter: blur($blur);
    -o-filter: blur($blur);
    -ms-filter: blur($blur);
    filter: blur($blur);
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin block-shadow {
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
}

@mixin drop-shadow($shadow) {
    filter: drop-shadow($shadow);
    -webkit-filter: drop-shadow($shadow);
}

@mixin box-sizing($sizing) {
    -webkit-box-sizing: $sizing;
    -moz-box-sizing: $sizing;
    -ms-box-sizing: $sizing;
    box-sizing: $sizing;
}

@mixin transition($transition) {
    transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation: $animations;
    -o-animation: $animations;
    animation: $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

// FLEXBOX Mixins
@mixin flex-box {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
        -moz-box-direction: reverse;
        -moz-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
        -moz-box-direction: reverse;
        -moz-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
        -moz-box-direction: normal;
        -moz-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-wrap($wrap: nowrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-flow($values: (row nowrap)) {
    // No Webkit Box fallback.
    -webkit-flex-flow: $values;
    -moz-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {

    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;

    // Box-Flex only supports a flex-grow value so let's grab the
    // first item in the list and just return that.
    @if type-of($fg) == 'list' {
        $fg-boxflex: nth($fg, 1);
    }

    -webkit-box-flex: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -moz-box-flex: $fg-boxflex;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin justify-content($justify) {
    @if $justify == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $justify == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $justify == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $justify == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $justify;
        -ms-flex-pack: $justify;
    }
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
}

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    -moz-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

@mixin flex-all($direction: row, $align: flex-start, $justify: flex-start, $wrap: nowrap) {
    @include flex-box;
    @include flex-direction($direction);
    @include align-items($align);
    @include justify-content($justify);
    @include flex-wrap($wrap);
}

@mixin mobile() {
    @media (max-width: #{$breakpoint-mobile}) {
        @content;
    }
}

@mixin tablet() {
  @media (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin input-with-label() {
    @include flex-box;
    @include flex-direction(column);

    &.error {
        & > * {
            @include transition(all 0.5s);
        }
        label {
            color: $Red;
        }
        input {
            border: 1px solid $Red;
            color: $Red;
        }
    }

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    label {
        color: $Black;
        font-size: $FontLargeSize;
        margin-bottom: 11px;
        text-align: left;
        display: inline-block;
    }

    select, input {
        height: $InputHeight;
        line-height: $InputHeight;
    }

    input, select, textarea {
        background-color: $White;
        width: 100%;
        margin: 0;
        @include border-radius(5px);
        text-indent: 5px;
        font-size: $FontMediumSize;
        @include box-sizing(border-box);
        border: 1px solid $InputBorderColor;

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px white inset;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .radio-btn {
        @include flex-box();
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(flex-start);

        input {
            margin-left: 5px;

            @media (max-width: $breakpoint-mobile) {
                margin-right: 10px;
            }
        }
    }

    .form-text {
        position: absolute;
        margin-top: -3px;
        font-size: $FontMediumSize;

        &.text-danger {
            color: $Red
        }
    }
}

@mixin add-btn-toggle {
    z-index: 2;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    color: $White;
    cursor: pointer;
    font-size: 21px;
    background: $Blue;
    line-height: 58px;
    text-align: center;
    @include border-radius(100%);
    @include transition(all ease-out 200ms);
    @include transform(scale(1.1, 1.1) translate3d(0, 0, 0));
    @include box-shadow(0 2px 5px 0 rgba(0, 0, 0, .26));
    @include flex-all(row, center, center);

    &:hover {
        @include transform(scale(1.2, 1.2) translate3d(0, 0, 0));
    }
}

@mixin buttons-designs {
    cursor: pointer;
    background-color: transparent;
    @include border-radius(0);
    font-size: $FontMediumSize;
    font-weight: $FontRegular;
    border: 0;
    height: 40px;
    padding-right: 15px;
    padding-left: 15px;
    @include border-radius(5px);

    @media (max-width: $breakpoint-mobile) {
        height: 100%;
        @include flex-box;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);

        i {
            margin-right: 0 !important;
            margin-bottom: 5px;
        }
    }

    i {
        margin-right: 5px;
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

@mixin label-design {
    display: block;
    color: $Gray;
    font-size: $FontMediumSize;
    font-weight: $FontRegular;
    margin-bottom: 11px;

    .required {
        margin-left: 5px;
        color: $Red;
    }
}

@mixin placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content
    }
    &::placeholder {
        @content
    }
}


