@import "./mixin";
@import "./variables";

.custom-on-boarding-modal-panel {
  height: auto !important;

  @include mobile() {
    max-width: 90vw !important;
    width: 90vw !important;

    .mat-dialog-container {
      //dln-on-boarding-modal {
      .title {
        h2 {
          margin: 0 !important;
        }
      }
      .subtitle {
        margin: 0 !important;
        letter-spacing: 0.5px;
      }
      //  }
    }
  }
}
