@import '~@angular/material/theming';
@import "../colors";
@import "../mixin";

mat-option .mat-option-text {
    display: block;
}

// Plus imports for other components in your app.
$dln-blue: (
    50: #e3f2fd,
    100: #bbdefb,
    200: #90caf9,
    300: #64b5f6,
    400: #42a5f5,
    500: #2196f3,
    600: #1e88e5,
    700: #1976d2,
    800: #1565c0,
    900: #0d47a1,
    A100: #82b1ff,
    A200: #448aff,
    A400: #2979ff,
    A700: #2962ff,
    LIGHT: #039be5,
    NORMAL: $Blue,
    DARK: $BlueHover,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: $white-87-opacity,
        900: $white-87-opacity,
        A100: $black-87-opacity,
        A200: white,
        A400: white,
        A700: white,
        LIGHT: $White,
        NORMAL: $White,
        DARK: $White
    )
);

$dln-red: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #f44336,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    LIGHT: #e53935,
    NORMAL: $Red,
    DARK: $RedHover,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: $white-87-opacity,
        900: $white-87-opacity,
        A100: $black-87-opacity,
        A200: white,
        A400: white,
        A700: white,
        LIGHT: $White,
        NORMAL: $White,
        DARK: $White
    )
);

$dln-green: (
    50: #e0f7fa,
    100: #b2ebf2,
    200: #80deea,
    300: #4dd0e1,
    400: #26c6da,
    500: #00bcd4,
    600: #00acc1,
    700: #0097a7,
    800: #00838f,
    900: #006064,
    A100: #84ffff,
    A200: #18ffff,
    A400: #00e5ff,
    A700: #00b8d4,
    LIGHT: $GreenLight,
    NORMAL: $Green,
    DARK: $GreenDark,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
        LIGHT: $GreenLight,
        NORMAL: $Green,
        DARK: $GreenDark,
    )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$dln-app-primary: mat-palette($dln-blue, NORMAL, LIGHT, DARK);
$dln-app-accent: mat-palette($dln-green, NORMAL, LIGHT, DARK);

// The warn palette is optional (defaults to red).
$dln-app-warn: mat-palette($dln-red, NORMAL, LIGHT, DARK);

// Create the theme object (a Sass map containing all of the palettes).
$dln-app-theme: mat-light-theme($dln-app-primary, $dln-app-accent, $dln-app-warn);

// Include theme styles for core and each flight-report used in your app.
// Alternatively, you can import and @include the theme mixins for each flight-report
// that you are using.
@include angular-material-theme($dln-app-theme);

body {

    .cdk-overlay-dark-backdrop {
        background: rgba(0, 0, 0, 0.1);
    }

    .mat-dialog-content {
        margin: 0;
    }

    .mat-dialog-container {
        @include border-radius(5px);
        @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.22));
    }

    mat-checkbox.white-checkbox {
        &:not(.mat-checkbox-checked) {
            .mat-checkbox-frame {
                background: $White;
                border-color: $White;
            }
        }
    }

    .cdk-overlay-container {

        .cdk-overlay-pane {

            &.overflow-visible {
                mat-dialog-container {
                    overflow: visible;
                    padding: 0 24px;
                }
            }

            &.no-padding {
                mat-dialog-container {
                    padding: 0;
                }
            }

        }

        .cdk-overlay-pane.large-height {
            .mat-dialog-content {
                max-height: 90vh;
            }
        }

        .full-width + * > .cdk-overlay-pane {
            width: 100%;
        }

        .mat-dialog-container-draggable {
            mat-dialog-container.mat-dialog-container {
                padding: 0;
                box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.22);
            }
        }
    }

    .mat-menu-panel {

        &.dark-menu-background {
            background-color: $BlueDarker;

            .mat-menu-content {
                background-color: $BlueDarker;
                padding-left: 10px;
                padding-right: 10px;
            }

            .mat-menu-item {
                color: white;
                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                font-size: $FontMediumSize;
                @include border-radius(2px);
                height: 30px;
                line-height: 30px;

                &:hover, &.selected {
                    background-color: $BlueLight;
                    color: white;
                }
            }
        }

        &.blue-menu-background {
            background-color: $BlueLight;

            .mat-menu-content {
                background-color: $BlueLight;
                padding-left: 10px;
                padding-right: 10px;
            }

            .mat-menu-item {
                font-size: $FontMediumSize;
                line-height: 1;
                color: white;
                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                height: 25px;

                &:hover {
                    color: $Black;
                    background: none;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $White;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                }

                @media (max-width: $breakpoint-mobile) {
                    height: 60px;
                    min-width: 60vw;
                    font-size: $FontLargeSize;
                }
            }
        }

        &.menu-mobile-content {
            min-width: calc(100% - 60px) !important;
            max-width: 100% !important;
            background-color: $BlueDarker;
            margin: 0 30px;

            .mat-menu-item {
                color: $White;
                font-family: "Open Sans", sans-serif;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                background-color: $BlueDarker;
                height: 60px;
                font-size: 22px;

                &.selected {
                    color: $Green;
                }

                &:hover {
                    background-color: $BlueDark;
                }
            }
        }

    }

    mat-slide-toggle {

        .mat-slide-toggle-bar {
            width: 30px;
        }

        &.mat-checked {


            &:not(.mat-disabled) {
                .mat-slide-toggle-bar {
                    background-color: $Green !important;
                    border: solid 1px #108995;

                    .mat-slide-toggle-thumb-container {
                        transform: translate3d(16px, 0, 0);

                        .mat-slide-toggle-thumb {
                            background-color: #ffffff;
                            box-shadow: 0 0 2px 1px rgba(29, 70, 128, 0.26);
                        }
                    }
                }
            }
        }

        &.blue-toggle-bar {
            .mat-slide-toggle-bar {
                background-color: $BlueLight;
                border: 1px solid #1C5CB6;
            }
        }

        .mat-slide-toggle-bar {
            background-color: #e1224f;
            border: solid 1px #9c0226;
        }

        .mat-slide-toggle-thumb {
            background-color: #ffffff;
            box-shadow: 0 0 2px 1px rgba(29, 70, 128, 0.26);
        }

        .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
            background-color: #ffffff;
            box-shadow: 0 0 2px 1px rgba(29, 70, 128, 0.26);
        }

        .mat-slide-toggle-thumb-container {
            height: 12px;
            width: 12px;
            top: 0;

            .mat-slide-toggle-thumb {
                height: 12px;
                width: 12px;
            }
        }
    }


    .red-snackbar {
        background-color: $Red;
        .mat-simple-snackbar-action {
            color: white;

            .mat-button-wrapper {
                font-weight: $FontBold;
            }
        }
    }
}

///deep/ .cdk-overlay-pane {
//    width: calc(100% - 20px);
//    margin-right: 20px;
//    .cdk-overlay-connected-position-bounding-box {
//        width: 100%!important;
//    }
//}
